import {required} from "vuelidate/lib/validators";

export default {
    fields: {
        text: {
            label: 'Season Label',
            type: 'text',
            validations: {required}
        },
        date_start: {
            label: 'Start Date',
            type: 'date',
            validations: {required}
        },
        date_end: {
            label: 'End Date',
            type: 'date',
            validations: {required}
        },
    }
}