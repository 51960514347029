<template>
    <div class="edit-current-season-modal">
        <b-button variant="primary" style="margin-bottom: 8px" block @click="showModal()">Set Current Season</b-button>
        <b-modal :id="'current_season_modal'" @ok="save" hide-header>
            <b-form-select v-model="current_season" :options="seasons"></b-form-select>
        </b-modal>
    </div>
</template>

<script>

import Utils from "@/providers/Utils";

export default {
    name: "EditCurrentSeasonModal",
    data() {
        return {
            current_season: null,
            seasons: null,
        }
    },
    methods: {
        async showModal() {
            this.current_season = await this.$api.loadCurrentSeason()
            this.$bvModal.show('current_season_modal')
        },
        save() {
            this.$api.writeCurrentSeason(this.current_season)
        },
    },
    created() {
        this.seasons = Utils.getAsset('season', this.$store.state.assets, true);
    },
}
</script>

<style scoped>

</style>