<template>

    <div class="job-record">
        <b-card>
            <router-link class="m-2" to="/">
                <b-icon-arrow-left></b-icon-arrow-left>
                Back
            </router-link>

            <b-card-header>
                <h2>View Assigned Jobs</h2>
            </b-card-header>
            <b-card-body>
                <table class="table">
                    <thead>
                    <tr>
                        <td>Staff</td>
                        <td>Job Type</td>
                        <td>Date Assigned</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="job in assignedJobs" v-bind:key="job">
                        <td>{{ job.staff_member }}</td>
                        <td>{{ job.task_type }}</td>
                        <td>{{ new Date(job.date.nanoseconds).toDateString() }}</td>
                    </tr>
                    </tbody>
                </table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
export default {
    name: "AssignedJobs",
    data() {
        return {
            assignedJobs: [],
        }
    },
    beforeMount() {
        this.$api.getAssignedJobs().then(jobs => this.assignedJobs = jobs)

    },
    mounted() {
        this.$api.log('page: assigned jobs')
    }
}
</script>

<style scoped>

</style>
