<template>

    <div class="admin-asset-editor container">
        <asset-table
            :title="title"
            :table-columns="tableColumns"
            :asset-model="assetModel"
            :has-cost="hasCost"
            :asset-key="assetKey">
        </asset-table>
    </div>
</template>

<script>
import Vue from 'vue'

import Field from "../models/Field"
import PrimaryEquipment from "../models/PrimaryEquipment"
import SecondaryEquipment from "../models/SecondaryEquipment"
import Crop from "../models/Crop"
import Mix from "../models/Mix"
import StaffMember from "../models/StaffMember"
import Fertilizer from "../models/Fertilizer"
import Rate from "../models/Rate"
import Season from "../models/Season"
import {minValue, required} from "vuelidate/lib/validators"
import Population from "../models/Population";

export default Vue.extend({
    name: "AdminAssetEditor",
    props: {
        assetKey: String,
    },
    data() {
        return {
            assetModel: null,
            title: "",
            tableColumns: ['text'],
            hasCost: false,

            simpleModel: {
                fields: {
                    text: {
                        label: 'Value',
                        type: 'text',
                        validations: {
                            required,
                        },
                    },
                }
            },
        }
    },
    beforeMount() {

        if (this.assetKey === 'field') {

            this.assetModel = Field
            this.title = "Fields"
            this.tableColumns = ['text', 'farm']

        } else if (this.assetKey === 'location') {

            this.assetModel = this.simpleModel
            this.title = "Location"

        } else if (this.assetKey === 'primary_equipment') {

            this.assetModel = PrimaryEquipment
            this.title = "Primary Equipment"
            this.tableColumns = ['text', 'type', 'cost_type', 'cost']
            this.hasCost = true

        } else if (this.assetKey === 'secondary_equipment') {

            this.assetModel = SecondaryEquipment
            this.title = "Secondary Equipment"
            this.tableColumns = ['text', 'type', 'cost_type', 'cost']
            this.hasCost = true

        } else if (this.assetKey === 'crop') {

            this.assetModel = Crop
            this.title = "Crops"
            this.tableColumns = ['text', 'type']
            this.hasCost = true

        } else if (this.assetKey === 'mix') {

            this.assetModel = Mix
            this.title = "Spraying Mixes"
            this.tableColumns = ['text', 'type']
            this.hasCost = true

        } else if (this.assetKey === 'manure_source') {

            this.assetModel = this.simpleModel
            this.title = "Manure Sources"

        } else if (this.assetKey === 'misc_task_type') {

            this.assetModel = this.simpleModel
            this.title = "Misc. Tasks"

        } else if (this.assetKey === 'staff_member') {

            this.assetModel = StaffMember
            this.tableColumns = ['first_name', 'last_name', 'hire_date']
            this.title = "Staff Members"
            this.hasCost = true

        } else if (this.assetKey === 'fertilizer') {

            this.assetModel = Fertilizer
            this.title = "Fertilizer"
            this.hasCost = true

        } else if (this.assetKey === 'population') {

            this.assetModel = Population
            this.tableColumns = ['text', 'units']
            this.title = "Population"

        } else if (this.assetKey === 'rate') {

            this.assetModel = Rate
            this.title = "Spraying / Fertilizer Rates"

        } else if (this.assetKey === 'season') {

            this.assetModel = Season
            this.tableColumns = ['text', 'date_start', 'date_end']
            this.title = "Growing Seasons"

        }
    },
    async mounted() {
        this.$api.log('page: admin asset editor')
    }
})
</script>

<style scoped>

</style>
