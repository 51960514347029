<template>
    <div class="gauge-chart" id="chart-div">
        <highcharts ref="chart" :options="chartOptions"></highcharts>
    </div>
</template>

<script>

import Vue from 'vue'
import Highcharts from 'highcharts'
// import SolidGauge from 'highcharts/modules/solid-gauge'
//
// SolidGauge(Highcharts)

export default Vue.extend({
    name: "GaugeChart",
    props: {
        chartTitle: String,
        dataPoint: Number,
    },
    computed: {
        chartOptions() {
            let filledOptions = this.getBaseOptions()
            return filledOptions
        }
    },
    methods: {
        getBaseOptions() {
            return {
                chart: {
                    type: 'solidgauge',
                    height: '80%'
                },
                title: {
                    text: this.chartTitle,
                    style: {'fontSize': '16px'},
                },
                xAxis: {
                    categories: null,
                },
                credits: {
                    enabled: false
                },
                pane: {
                    center: ['50%', '85%'],
                    size: '100%',
                    startAngle: -90,
                    endAngle: 90,
                    background: {
                        backgroundColor: '#404E7C',
                        innerRadius: '80%',
                        outerRadius: '100%',
                        shape: 'arc'
                    }
                },
                tooltip: {
                    enabled: false
                },
                yAxis: {
                    stops: [
                        [0.1, '#D1495B'],
                        [0.5, '#FFDD4A'],
                        [0.9, '#009E82'],
                    ],
                    lineWidth: 0,
                    tickWidth: 0,
                    minorTickInterval: null,
                    tickAmount: 2,
                    labels: {
                        enabled: false,
                    },
                    min: 0.5,
                    max: 12,
                },
                plotOptions: {
                    solidgauge: {
                        innerRadius: '80%',
                        outerRadius: '100%',
                        dataLabels: {
                            y: -45,
                            borderWidth: 0,
                            useHTML: true
                        }
                    }
                },
                series: [{
                    data: [this.dataPoint],
                    dataLabels: {
                        format:
                            '<div style="text-align:center">' +
                            '<span style="font-size:25px">{y:.2f}</span><br/>' +
                            '<span style="font-size:12px;opacity:0.4">acres / hour</span>' +
                            '</div>'
                    },
                    tooltip: {
                        valueSuffix: ' acres / hour'
                    }
                }]
            }
        }
    }
})

</script>

<style scoped>


</style>