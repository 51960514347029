
export default {
    fields: {
        text: {
            label: 'Mix',
            type: 'text',
        },
        type: {
            label: 'Spraying Type',
            type: 'select',
            options: [
                'pre_emergent', //TODO: Remove once Henry finishes converting assets
                'post_emergent', //TODO: Remove once Henry finishes converting assets
                'pre_emergent_corn',
                'post_emergent_corn',
                'post_emergent_alfalfa',
                'top_dress',
                'fungicide',
                'burn_down',
            ]
        },
    }
}
