<template>
    <div class="container time-entry">
        <div class="row">
            <b-form-input
                class="col-5"
                type="number"
                step="1"
                min="0"
                max="23"
                :state="state"
                v-model="hours"
                :v-on:change="calculateTime()">
            </b-form-input>
            <div
                class="col-2"
                style="text-align: center; margin-bottom: auto; margin-top: auto">
                :
            </div>
            <b-form-input
                class="col-5"
                type="number"
                step="1"
                min="00"
                max="59"
                :state="state"
                v-model="minutes"
                :v-on:change="calculateTime()">
            </b-form-input>
        </div>
    </div>
</template>

<script>
import Validation from "../providers/Validation";

export default {
    name: "TimeEntry",
    props: {
        time: Number,
        state: Boolean,
        value: Number
    },
    data() {

        return {
            hours: null,
            minutes: null,
        }
    },
    methods: {
        /**
         * calculates the time as a number with decimal
         */
        calculateTime() {
            if (this.minutes?.length == 1) {
                this.minutes = "0" + this.minutes
            }
            if (this.minutes?.length > 2) {
                this.minutes = this.minutes.substr(1, 2)
            }
            if (this.hours && this.minutes) {
                let tempTime = (+this.hours + (+this.minutes / 60))
                tempTime = Math.round(tempTime * 100)
                this.time = tempTime / 100
                this.$emit("input", this.time)
            } //else this.$emit("input", null)
        },
    },
    mounted() {
        console.log('TimeEntry:mounted  this.value=', this.value)
        let minutesDecimal = +this.value % 1
        this.hours = Math.floor(+this.value)
        this.minutes = Math.round(minutesDecimal * 60)
    },
    // watch: {
    //     value(newValue, oldValue) {
    //         console.log(newValue)
    //         let minutesDecimal = +newValue % 1
    //         this.hours = Math.floor(+newValue)
    //         this.minutes = Math.round(minutesDecimal * 60)
    //     }
    // }
}
</script>

<style scoped>
</style>
