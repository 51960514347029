<template>

    <div class="asset-table mt-2">
        <b-card>
            <b-card-header v-if="showHeader">
                <router-link class="m-2 mb-3" to="/admin/dashboard">
                    <b-icon-arrow-left></b-icon-arrow-left>
                    Back
                </router-link>

                <h2>Edit {{ title }}
                </h2>
            </b-card-header>
            <b-card-body>
                <b-form class="mb-2" v-if="showHeader">
                    <b-row>
                        <b-col cols="10">
                            <b-form-group label="Search">
                                <b-form-input v-model="searchText" type="text"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="2">
                            <b-form-group :label="$mq == 'sm' ? ' ' : 'Show Archived'">
                                <b-form-checkbox v-model="showArchived" switch></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
                <b-button @click="create" class="btn-block mb-2 p-2">
                    <b-icon font-scale="2" icon="plus-circle"></b-icon>
                </b-button>
                <table v-bind:class="$mq == 'sm' ? 'table table-responsive' : 'table'">
                    <thead>
                    <tr>
                        <td v-for="header in tableHeaders" v-bind:key="header">
                            {{ header }}
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(item, index) in shownItems" v-bind:key="index">
                        <td v-for="col in tableColumns" v-bind:key="col">
                            {{ item[col] }}
                        </td>
                        <td v-if="hasCost">
                            <cost-badge :cost="latestCost(item)"></cost-badge>
                        </td>
                        <td class="mod-button" v-if="!item.archived && hasCost">
                            <cost-change-editor
                                :cost-changes="item.cost_changes"
                                :item="item"
                                @save-costs="saveCosts"
                                :id="'cost' + item.id">
                            </cost-change-editor>
                        </td>
                        <td class="mod-button" v-if="!item.archived">
                            <item-edit-modal
                                :id="'edit' + item.id"
                                :item="item"
                                :asset-key="assetKey"
                                @save-item="saveItem"
                                :fieldModel="assetModel">
                            </item-edit-modal>
                        </td>
                        <td v-else>
                            <b-badge>ARCHIVED</b-badge>
                        </td>

                        <!--                        Restore from archived button-->
                        <td class="mod-button" v-if="item.archived">
                            <b-button
                                class="btn btn-block btn-sm" variant="primary"
                                @click="restoreItem(item, index)">
                                <b-icon-arrow-counterclockwise font-scale="2"></b-icon-arrow-counterclockwise>
                            </b-button>
                        </td>

                        <!--                        delete button-->
                        <td class="mod-button" v-else>
                            <b-button
                                class="btn btn-block btn-sm" variant="danger"
                                @click="deleteItem(item, index)">
                                <b-icon-trash font-scale="2"></b-icon-trash>
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-card-body>
        </b-card>

        <item-edit-modal
            :no-button="true"
            id="new_item"
            :item="newItem"
            :asset-key="assetKey"
            @save-item="createItem"
            :fieldModel="assetModel">
        </item-edit-modal>
    </div>

</template>

<script>

import Vue from 'vue'
import {required} from "vuelidate/lib/validators"
import Utils from '@/providers/Utils'
import CostChangeEditor from "./CostChangeEditor";
import DataService from "../providers/DataService";

export default Vue.extend({
    name: "AssetTable",
    components: {CostChangeEditor},
    props: {
        title: String,
        assetKey: String,
        assetModel: Object,
        tableColumns: Array,
        hasCost: Boolean,
        showHeader: {
            type: Boolean,
            default: true
        },
        emitSave: {
            type: Boolean,
            default: false
        },
        filters: Object,
    },
    data() {
        return {
            asset: [],
            newItem: null,
            searchText: '',
            showArchived: false
        }
    },
    computed: {
        tableHeaders() {
            return this.tableColumns.map((col) => {
                let field = this.assetModel.fields[col]
                if(field) {
                    let units = field.units ? ' - ' + field.units : ''
                    return field.label + ' ' + units
                }
                return ''
            })
        },
        shownItems() {
            if (this.asset) {
                //First, filter by archived, and search box text
                let filteredAsset = this.asset.filter((item) => {
                    let notArchived = (!item.archived || this.showArchived)

                    if (this.searchText && item.text) { //If search text, filter by it
                        return (item.text.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) && notArchived
                    } else {
                        return notArchived
                    }
                })

                //Then, filter by season / field, if a filter object was passed as prop
                if(this.filters && this.filters.season && this.filters.field) {
                    filteredAsset = DataService.filterBySeason(filteredAsset, this.filters.season)
                    filteredAsset = filteredAsset.filter((item) => {
                        return item.field === this.filters.field
                    })
                }

                return filteredAsset
            } else {
                return this.asset
            }
        },
    },
    methods: {
        create() {
            this.buildNewItem()
            this.$bvModal.show('new_item')
        },
        restoreItem(item, index) {
            item['archived'] = false
            this.$api.setItem(item, this.assetKey)
            this.$forceUpdate()
        },
        deleteItem(item, index) {
            //Set to archived
            item['archived'] = true
            this.$api.setItem(item, this.assetKey)
            this.$forceUpdate()
        },
        buildNewItem() {
            this.newItem = {}
            Object.keys(this.assetModel.fields).forEach((field) => {
                this.newItem[field] = null
            })
        },
        async saveItem(item, assetKey) {
            if (this.emitSave) {
                this.$emit('save-asset', item, assetKey)
            } else {
                await this.$api.setItem(item, assetKey)
                this.$api.loadAssets()
            }
        },
        createItem(item, assetKey) {
            this.saveItem(item, assetKey)
            this.asset.push(item)
        },
        fetchAsset() {
            this.asset = Utils.getAsset(this.assetKey, this.$store.state.assets, false)
        },
        saveCosts(item, cost_changes) {
            item['cost_changes'] = cost_changes
            this.$api.setItem(item, this.assetKey)
            this.$forceUpdate()
        },
        latestCost(item) {
            if(item.cost_changes) {
                return Number(item.cost_changes[item.cost_changes.length - 1]?.value)
            }
            return null
        }
    },
    beforeMount() {
        this.fetchAsset()
        this.buildNewItem()
    }
})
</script>

<style scoped>

.mod-button {
    width: 40px;
}

</style>