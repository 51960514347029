/**
 * Useful functions for mapping between our field models (e.g. models/PrimaryEquipment.js) and structures
 * used by Vuelidate for validation. These functions allow you to select which fields from a given
 * field model are to be displayed and validated on a given form.
 *
 * Used by JobRecord and AssetEditModal
 *
 * This was taken from Wellcoaches and tweaked slightly
 */

import * as _ from 'lodash'
import i18n from '@/providers/i18n'

export default {

    /**
     * Builds the form data model for Vuelidate
     */
    buildFormModel (fieldModel) {
        let fieldNames = Object.keys(fieldModel.fields)
        const form = {}
        _.each(fieldNames, (fn) => {
            form[fn] = null
        })
        return form
    },

    /**
     * Populates the form data model for Vuelidate, using field values from the given model instance.
     */
    populateFormModel (form, fieldModel, modelInstance) {
        let fieldNames = Object.keys(fieldModel.fields)
        _.each(fieldNames, (fn) => {
            if (fieldModel.fields[fn]) {
                if (fieldModel.fields[fn].type == 'checks') {
                    // for a checkbox group, convert a SET db value (e.g. "PRIMARY, SPECIALIST")
                    // to array for binding to control
                    if (modelInstance[fn] && modelInstance[fn].length) {
                        form[fn] = modelInstance[fn].split(',')
                    } else {
                        form[fn] = []
                    }
                } else {
                    //Make sure it is null, not undefined. Undefined can break validation
                    form[fn] = modelInstance[fn] || null
                }
            }
        })
    },

    /**
     * Copies the Vuelidate form model values back to the given model instance.
     */
    extractFormModel (form, fieldModel, modelInstance) {
        let fieldNames = Object.keys(fieldModel.fields)
        _.each(fieldNames, (fn) => {
            if (fieldModel.fields[fn].type == 'checks') {
                // for a checkbox group, convert an array of selected items back to
                // a SET db value (e.g. "PRIMARY, SPECIALIST")
                if (form[fn].$model) {
                    modelInstance[fn] = form[fn].$model.join(',')
                }
            } else {
                modelInstance[fn] = form[fn].$model
            }
        })
    },

    /**
     * Populates the form validation stucture for Vuelidate, using validations for the selected field names
     * defined in the given field model (e.g. Participant).
     */
    buildFormValidations (fieldModel, modelInstance) {
        let fieldNames = Object.keys(fieldModel.fields)
        const vs = {}
        _.each(fieldNames, (fn) => {
            const field = fieldModel.fields[fn]
            if (field) {

                let show = field.showIf ? field.showIf(modelInstance) : true

                if (field.validations && show) {
                    vs[fn] = field.validations
                } else {
                    vs[fn] = {}
                }
            } else {
                new Error('ouch')
            }
        })

        return { form: vs }
    },

    /**
     * Returns validation state for a field
     *
     * $v is the vuelidate instance
     */
    state($v, field) {
        const f = $v.form[field]

        if (f) {
            let d = f.$dirty ? !f.$error : null
            return d
        } else {
            return null
        }
    },

    /**
     * Returns a feedback string for validation errors.
     * Will use an invalidFeedback object from the field,
     * or the defaultFeedback below if not present.
     *
     * $v is the vuelidate instance
     */
    invalidFeedback($v, field, fieldName) {
        let fb = ''
        _.each(_.keys(field.validations), (v) => {
            if (!fb && !$v.form[fieldName][v]) {

                const defaultFeedback =  {
                    required: 'INPUT_REQUIRED',
                    minValue: 'VALUE_TOO_LOW',
                    maxValue: 'VALUE_TOO_HIGH',
                }

                if(field.invalidFeedback){
                   fb = i18n.gt( field.invalidFeedback[v] )
                } else {
                    fb = i18n.gt( defaultFeedback[v] )
                }
            }
        })
        return fb
    },
}
