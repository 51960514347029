<template>
    <div class="cost-badge">
        <b-badge
            :variant="variant"
            class="cost">
            <template>
                {{ '$' + costLabel}}
            </template>
            <template>
                {{appendString}}
            </template>
        </b-badge>
    </div>
</template>

<script>
export default {
    name: "CostBadge",
    props: {
        cost: Number,
        status: Object,
        append: String,
    },
    computed: {
        costLabel() {
            //console.log('CostBadge:costLabel  this.cost=', this.cost)
            if(this.cost == null) {
                return ''
            }
            return this.cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})

        },
        variant() {
           if(!this.cost) {
               return 'danger'
           }
           if(this.status) {
               return this.status.variant
           }
           return 'primary'
        },
        appendString() {
            if(this.append) {
                return this.append
            }
            if(this.variant !== 'primary' && this.status && this.status.message) {
                return this.status.message
            }
            return ''
        },
    }
}
</script>

<style scoped>

.cost {
    font-weight: bold;
    font-size: 18px;
}


</style>
