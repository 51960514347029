
export default {
    fields: {
        text: {
            label: 'Mix',
            type: 'number',
            units: 'Gal / Acre'
        },
    }
}
