<template>

    <div class="admin-field-list container-fluid">

        <b-card>
            <router-link class="m-2" to="/admin/dashboard">
                <b-icon-arrow-left></b-icon-arrow-left>
                Back
            </router-link>

            <b-card-header>
                <h2>Field List</h2>
            </b-card-header>
            <b-card-body>
                <b-form class="mb-2">
                    <b-row>
                        <b-col cols="10">
                            <b-form-group label="Search">
                                <b-form-input v-model="searchText" type="text"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="2">
                            <b-form-group :label="$mq == 'sm' ? ' ' : 'Show Archived'">
                                <b-form-checkbox v-model="showArchived" switch></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>

                <div class="container-xxl">
                    <b-row>
                        <b-col>
                            <item-table
                                :items="shownItems()"
                                :shown-columns="tableColumns">

                                <template v-slot:customField="slotProps">
                                    <div v-if="slotProps.row.col === 'link'">
                                        <div v-if="!slotProps.row.item.archived">
                                            <router-link class="btn btn-primary"
                                                         :to="`/admin/view/field/${slotProps.row.item.id}`">
                                                <b-icon-link font-scale="2"></b-icon-link>
                                            </router-link>
                                        </div>
                                        <div v-else>
                                            <b-badge>ARCHIVED</b-badge>
                                        </div>
                                    </div>
                                </template>
                            </item-table>

                        </b-col>
                    </b-row>
                </div>
            </b-card-body>
        </b-card>
    </div>

</template>

<script>
import Vue from 'vue';
import ItemTable from "@/components/ItemTable";
import Utils from "@/providers/Utils";

export default Vue.extend({
    name: "AdminFieldList",
    components: {ItemTable},
    data() {
        return {
            asset: [],
            searchText: '',
            showArchived: false,
            tableColumns: {
                text: 'Field Number / Name',
                farm: 'Associated Farm',
                link: 'Field Page'
            }
        }
    },
    methods: {
        shownItems() {
            if (this.asset) {
                return this.asset.filter((item) => {
                    let notArchived = (!item.archived || this.showArchived)

                    if (this.searchText && item.text) { //If search text, filter by it
                        return (item.text.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) && notArchived
                    } else {
                        return notArchived
                    }
                })
            } else {
                return this.asset
            }
        }
    },
    beforeMount() {
        this.asset = Utils.getAsset('field', this.$store.state.assets, false)
    },
    mounted() {
        this.$api.log('page: admin field list')
    }
})
</script>

<style scoped>

</style>
