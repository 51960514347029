<template>
    <div class="filter-widget">
        <b-form class="mb-2">
            <b-row>
                <b-col class="cols-md-3">
                    <b-form-group label="Season">
                        <b-form-select
                            v-model="filters.season" :options="getAsset('season')" disabled></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col class="cols-md-3">
                    <b-form-group label="Task">
                        <b-form-select
                            v-model="filters.task_type"
                            :options="task_types"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col class="cols-md-3">
                    <b-form-group label="Date Start">
                        <b-form-input
                            v-model="filters.date_start"
                            type="date"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col class="cols-md-3">
                    <b-form-group label="Date End">
                        <b-form-input
                            v-model="filters.date_end"
                            type="date"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="cols-md-1">
                    <b-form-group label="ID">
                        <b-form-input
                            type="number"
                            v-model="filters.integer_id"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col class="cols-md-5">
                    <b-form-group label="Name">
                        <b-form-select
                            v-model="filters.staff_member"
                            :options="getAsset('staff_member')"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col class="cols-md-5">
                    <b-form-group label="Field">
                        <gorges-vue-typeahead
                            v-model="filters.field"
                            :min-matching-chars="0"
                            ref="typeahead_field"
                            :data="getAsset('field')"
                            :disabled="!!frozenField"></gorges-vue-typeahead>
                    </b-form-group>
                </b-col>
                <b-col class="cols-md-1">
                    <b-btn class="btn mt-1" @click="clear">
                        <b-icon-arrow-counterclockwise font-scale="2"></b-icon-arrow-counterclockwise>
                    </b-btn>
                </b-col>
            </b-row>
        </b-form>
    </div>

</template>

<script>
import LocalAssets from "../providers/LocalAssets";
import Utils from "../providers/Utils";
import GorgesVueTypeahead from "@/components/gorges-vue-typeahead/GorgesVueTypeahead";

export default {
    name: "FilterWidget",
    components: {GorgesVueTypeahead},
    props: {
        value: Object, //Filter object, used by v-model
        assets: Object,
        frozenField: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            filters: {},
            current_season: null,
            task_types: LocalAssets.taskTypes
        }
    },
    watch: {
        filters(newVal) {
            this.$emit('input', newVal)
            this.$store.commit('set', {
                field: 'filters',
                value: newVal
            })
        }
    },
    methods: {
        clear() {
            this.filters = {
                season: this.current_season.id,
                task_type: null,
                date_start: this.current_season.date_start,
                date_end: null,
                field: this.frozenField,
                staff_member: null,
                integer_id: null,
            }
            this.$refs.typeahead_field.inputValue = Utils.getAssetItem(this.fieldId, 'field', this.assets)
        },
        getAsset(key) {
            return Utils.getAsset(key, this.assets)
        },
    },
    async mounted() {
        this.current_season = Utils.getAssetItem(await this.$api.loadCurrentSeason(), 'season', this.assets)

        if (this.$store.state.filters) {
            this.filters = this.$store.state.filters
            this.filters.season = this.current_season.id
            this.filters.date_start = this.current_season.date_start
            this.filters.field = this.frozenField
        } else {
            await this.clear()
        }
    }
}
</script>

<style scoped>

</style>
