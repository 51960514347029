<template>

    <div class="task-table" v-if="jobRecords && assets">
        <div class="cost-div p-2 pr-5 mb-2">
            <h5 style="color: white">Total Cost: ${{totalCost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</h5>
        </div>
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            per-page="20"></b-pagination>
        <div class="table-responsive table-container">
            <table class="table table-striped">
                <thead>
                <tr>
                    <td v-for="(label, col)  in shownColumns" v-bind:key="'head_' + col">
                        {{ label }}
                    </td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="task in paginatedRecords" v-bind:key="task.id">
                    <td v-for="(label, col) in shownColumns" v-bind:key="'body_' + col">

                        <b-btn :to="'/admin/jobrecord/' + task.id" v-if="col === 'edit'">
                            <b-icon-pencil font-scale="2"></b-icon-pencil>
                        </b-btn>

<!--                        <template v-if="col === 'cost'">{{task}}</template>-->
                        <cost-breakdown v-if="col === 'cost'"
                                    :task="task">
                        </cost-breakdown>

                        <template v-else>
                            {{ columnText(task, col) }}
                        </template>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import Vue from 'vue'
import Utils from '@/providers/Utils'
import DataService from "@/providers/DataService"
import TaskModel from "@/models/Task"
import i18n from "@/providers/i18n";
import CostService from "../providers/CostService";

export default Vue.extend({
    name: "TaskTable",
    props: {
        jobRecords: Array,
        assets: Object,
        shownColumns: Object,
    },
    data() {
        return {
            utils: Utils,
            currentPage: 1,
        }
    },
    computed: {
        shownRecords() {
            return DataService.expandJobRecords(this.jobRecords, this.assets, true)
        },
        paginatedRecords() {
            return this.shownRecords.slice((this.currentPage-1) * 20, (this.currentPage) * 20)
        },
        totalCost() {
            return CostService.totalCost(this.shownRecords)
        },
        rows() {
            return this.shownRecords.length
        }
    },
    methods: {
        fieldLabel(col) {
            if (col === 'first_name') {
                return 'First Name'
            } else if (col === 'last_name') {
                return 'Last Name'
            } else {
                return i18n.gt(TaskModel.fields[col]?.label)
            }
        },
        getItemProp(task, key, prop) {
            let item = Utils.getAssetItem(task[key], key, this.assets)
            if (item) {
                return item[prop]
            }
            return null
        },
        columnText(task, col) {
            if (task[col] && task[col].text) {
                return task[col].text
            }
            return task[col]
        },
    },
})
</script>

<style scoped>

.bg-success {
    color: white;
}

.table-container {
    height: 500px;
    overflow: auto;
}

.cost-div {
    /*border-top: 1px solid #000;*/
    text-align: right;
    background-color: #002C45;
}

</style>