<template>
    <div class="item-table" v-if="items">
        <div class="table-responsive table-container">
            <table class="table table-striped">
                <thead>
                <tr>
                    <td v-for="(label, col)  in shownColumns" v-bind:key="'head_' + col">
                        {{ label }}
                    </td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(item, index) in items" v-bind:key="item.id">
                    <td v-for="(label, col) in shownColumns" v-bind:key="'body_' + col">
                        <slot name="customField" v-bind:row="{col: col, item: item, index: index}">
                           {{ item[col] }}
                        </slot>
                    </td>
                </tr>

                <tr v-if="items.length === 0">
                    <td :colspan="Object.keys(shownColumns).length">
                        <i>{{emptyMessage || "No items added yet"}}</i>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

/**
 * Simple table based on TaskTable but without cost information.
 * Has a slot for adding buttons instead of hard coded edit button
 */
import Vue from 'vue'
import Utils from '@/providers/Utils'

export default Vue.extend({
    name: "ItemTable",
    props: {
        items: Array,
        shownColumns: Object,
        emptyMessage: String,
    },
    data() {
        return {
            utils: Utils,
        }
    },
    methods: {
        fieldLabel(col) {
            if (col === 'first_name') {
                return 'First Name'
            } else if (col === 'last_name') {
                return 'Last Name'
            } else {
                return i18n.gt(TaskModel.fields[col]?.label)
            }
        },
    },
})
</script>

<style scoped>

.table-container {
    /*height: 500px;*/
    overflow: auto;
}

</style>
