import firebase from 'firebase'
import store from '@/store'
import Utils from "./Utils"
import Vue from 'vue'
import VueResource from 'vue-resource'
Vue.use(VueResource)

/**
 * The top level collection in firestore contains
 * different copies of the database to be used for staging / production.
 * In the future we could use this to accommodate different farms.
 * This string selects which database to use.
 *
 * Make sure this is willet_production when you deploy.
 */

const firebaseConfig = {
    apiKey: "AIzaSyCpx5ydVM0kQuOlay4fqVP3Ejx9F7xsZ1c",
    authDomain: "willetdairy-a8072.firebaseapp.com",
    projectId: "willetdairy-a8072",
    storageBucket: "willetdairy-a8072.appspot.com",
    messagingSenderId: "379016099586",
    appId: "1:379016099586:web:1814177a99d9b1d5faf41b",
    measurementId: "G-TCDCB3Q6JX"
}

const db = firebase
    .initializeApp(firebaseConfig)
    .firestore()
const analytics = firebase.analytics()

const assetKeys = [
    'staff_member',
    'primary_equipment',
    'secondary_equipment',
    'crop',
    'crop_variety',
    'mix',
    'rate',
    'manure_source',
    'field',
    'location',
    'misc_task_type',
    'fertilizer',
    'population',
    'season',
    'harvest_record'
]

const api = {
    db: db,
    analytics: analytics,
    farm_id: 'willet_production',
    firebase: firebase,

    initApp() {
        if (window.location.hostname.includes('localhost')) {
            store.commit('set', {
              field: 'devMode',
              value: true
            })
            this.farm_id = 'willet_staging'
            //this.farm_id = 'willet_production'    // *** DEBUG ***
        }

        //When the app loads restore the user
        firebase.auth().onAuthStateChanged((user) => {
            this.log('api: onAuthStateChanged  user=' + (user ? ' not null' : 'null'))
            store.commit('set', {
                field: 'user',
                value: user
            })
        })

        // 'summary' is a public route so we don't need to configure caching
        // This will prevent warning boxes / statues from showing up
        if (window.location.pathname !== '/public/summary') {

            //Enables caching for offline mode
            firebase.firestore().enablePersistence()
                .catch((err) => {
                    if (err.code === 'failed-precondition') {
                        window.alert('Multiple copies of the app are open at the same time. Close other tabs/windows for offline mode to function correctly')
                    } else if (err.code === 'unimplemented') {
                        window.alert('Your device does not support offline mode')
                    }
                })

            //Keeps track of if job_records is cached to show offline warning message
            db.collection(this.farm_id)
                .doc('data')
                .collection('job_records')
                .onSnapshot(
                    {includeMetadataChanges: true},
                    (snap) => {
                    console.log('Api:initApp:onSnapshot  snap.metadata.hasPendingWrites=', snap.metadata.hasPendingWrites)
                    this.log('api: job_records:onSnapshot  snap.metadata.hasPendingWrites=' + snap.metadata.hasPendingWrites)
                    store.commit('set', {
                        field: 'unsynced',
                        value: snap.metadata.hasPendingWrites
                    })
                })
        }

        // When the app first loads, get the assets into the store
        // this.getJobRecords()
        this.loadAssets()
        this.loadGlobalCosts()
    },

    async signIn(pin_code) {
        let real_pin_code = await this.loadPinCode();

        if (real_pin_code === pin_code) {
            return firebase.auth().signInAnonymously().then(() => {
                firebase.auth().onAuthStateChanged((user) => {
                    if (user) {
                        store.commit('set', {
                            field: 'user',
                            value: user
                        })
                        return Promise.resolve()
                    }
                })
            }).catch((error) => {
                return Promise.reject(error)
            })
        }
        return Promise.reject()
    },

    signInAdmin(email, password) {
        return firebase.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                store.commit('set', {
                    field: 'user',
                    value: userCredential.user
                })
                store.state.user.getIdTokenResult().then((idTokenResult) => {
                    console.log('Api:signInAdmin  idTokenResult=', idTokenResult)
                })
                return Promise.resolve()
            })
            .catch((error) => {
                return Promise.reject(error)
            })
    },

    fetchOneAsset(assetName, offline) {
        //Grabs asset from cache if requesting in offline mode
        let sourceParam = offline ? {source: 'cache'} : {}

        return db.collection(this.farm_id)
            .doc('assets')
            .collection(assetName)
            .get(sourceParam)
            .then((query) => {
                let items = {}
                query.forEach((doc) => {

                    //Assets may not have an their ID as a property, so here we manually add it
                    let item = doc.data()
                    item['id'] = doc.id
                    items[item.id] = item

                })
                return items
            })
    },

    /**
     * Loads ALL assets and returns them as an object:
     * {
     *     primary_equipment: [ ... ]
     *     secondary_equipment: [ ... ]
     *     ...
     * }
     *
     * If 'offline' is true, it will retrieve assets from browser cache instead of server
     * Also puts the object in the vuex store
     */
    async loadAssets(offline) {
        let assets = {}
        let promises = []

        //We are creating an array of promises so we can evaluate them all at once
        for (let asset_key of assetKeys) {
            promises.push(this.fetchOneAsset(asset_key, offline))
        }

        //The results from Promise.all will return in the order we put them in the promises array
        //We need to use this order to associate each with the right asset key
        await Promise.all(promises).then((result) => {
            let keyIndex = 0
            result.forEach((asset) => {
                assets[assetKeys[keyIndex]] = asset
                keyIndex++
            })
        })

        let count = 0
        for (let asset_key of assetKeys) {
            count += Object.keys(assets[asset_key]).length
        }
        this.log('api: load assets  count=' + count)
        console.log('Api:loadAssets  offline=', offline, '  count=', count)

        store.commit('set', {
            field: 'assets',
            value: assets
        })
        return assets
    },

    //Set all items for an asset
    setAsset(asset, assetKey) {
        let ref = db.collection(this.farm_id)
            .doc('assets')
            .collection(assetKey)

        asset.forEach((item) => {
            ref.doc(item.id).set(item)
        })
    },

    deleteItem(id, assetKey) {
        db.collection(this.farm_id)
            .doc('assets')
            .collection(assetKey)
            .doc(id).delete().catch((error) => console.log('API:deleteItem  error=', error))
    },

    //Set just one item for an asset
    setItem(item, assetKey) {
        if (!item.id) {
            item.id = Utils.generateId()
        }

        return db.collection(this.farm_id)
            .doc('assets')
            .collection(assetKey)
            .doc(item.id).set(item)
    },

    async loadGlobalCosts() {
        this.log('api: load global costs')
        let query = await db.collection(this.farm_id)
            .doc('global_costs').get()
        store.commit('set', {
            field: 'global_costs',
            value: query.data()
        })
        //console.log('Api:loadGlobalCosts  store.state.global_costs=', store.state.global_costs)
        return query.data()
    },

    setGlobalCost(cost_changes) {
        return db.collection(this.farm_id)
            .doc('global_costs')
            .set(cost_changes)
    },

    createJobRecord(jobRecord) {

        const doc = db.collection(this.farm_id)
            .doc('data')
            .collection('job_records')
            .doc()

        jobRecord['id'] = doc.id
        if(store.state.jobRecords) {
            //store.state.jobRecords[doc.id] = jobRecord
            store.commit('setAssoc', {
                field: 'jobRecords',
                key: doc.id,
                value: jobRecord
            })
        }

        const promise = doc.set(jobRecord)
        return promise
    },

    updateJobRecord(jobRecord, recordId=null) {

        // The Utils.scrub function removes null / undefined keys from an object.
        //
        // This is used before saving an existing job record.
        // Firestore is ok with adding new keys to an object before saving (e.g. archived)
        // IF AND ONLY IF it is not null. The vuelidate form stuff adds a bunch of empty keys
        // which we strip away with this function.

        jobRecord = Utils.scrub(jobRecord)

        //Update the record in the store so we don't have to refresh
        let recordIndex = store.state.jobRecords.findIndex((record) => {
            return record.id === jobRecord.id
        })
        if(recordIndex) {
            //store.state.jobRecords[recordIndex] = jobRecord
            store.commit('setAssoc', {
                field: 'jobRecords',
                key: recordIndex,
                value: jobRecord
            })
        }

        return db.collection(this.farm_id)
            .doc('data')
            .collection('job_records')
            .doc(jobRecord.id).update(jobRecord)
    },

    /**
     * Gets the list of job records
     * Adds the ID hash to the record object
     * Calculates an integer ID based on the order the records were created
     */
    async getJobRecords() {
        let records = null

        if(store.state.jobRecords) {
            records = store.state.jobRecords
        } else {
            let query = await db.collection(this.farm_id)
                .doc('data')
                .collection('job_records').get()

            //Add the hash IDs to the records
            records = query.docs.map((doc) => {
                let record = doc.data()
                record['id'] = doc.id
                return record
            })
        }

        //Now add a numeric ID based on the epoch time it was created
        let idx = 1
        records = _.sortBy(records, 'created')
        records = records.map((rec) => {
            rec['integer_id'] = idx
            idx++
            return rec
        })

        //Filter out archived records. We are keeping these around for the sake of
        //calculating accurate ID numbers above
        records = records.filter((rec) => {
            return !rec.archived
        })

        //store.state.jobRecords = records
        store.commit('set', {
            field: 'jobRecords',
            value: records
        })
        console.log('api: getJobRecords  idx=', idx, '  records.length=', records.length)
        this.log('api: getJobRecords  idx=' + idx + '  records.length=' + records.length)
        return records
    },

    async getJobRecord(id) {
        let query = await db.collection(this.farm_id)
            .doc('data')
            .collection('job_records')
            .doc(id).get()

        let record = query.data()
        record['id'] = id

        return record
    },

    async getAssignedJobs() {
        let query = await db.collection(this.farm_id)
            .doc('assets')
            .collection('assigned_job').get()
        return query.docs.map(doc => doc.data())
    },

    loadTranslations() {
        this.log('api: load translations')
        return Vue.http.get('/translations.csv')
            .then((response) => response.data)
    },

    async loadPinCode() {
        let query = await db.collection(this.farm_id).doc('meta').get()
        return query.data().pin_code
    },

    writePinCode(pin_code) {
        return db.collection(this.farm_id).doc('meta').set({pin_code: pin_code})
    },

    async loadCurrentSeason() {
        let query = await db.collection(this.farm_id).doc('meta').get()
        const current_season = query.data().current_season
        return current_season
    },

    writeCurrentSeason(current_season) {
        return db.collection(this.farm_id).doc('meta').set({current_season: current_season})
    },

    log(message) {
        this.analytics.logEvent(message);
    }
}

export default api;
