import {required} from "vuelidate/lib/validators";

export default {
    fields: {
        text: {
            label: 'Text',
            type: 'text',
            validations: {
                required
            }
        },
        type: {
            label: 'Type',
            type: 'select',
            options: {
                tillage: 'Tillage',
                planting: 'Planting',
                mowing: 'Mowing',
                merging: 'Merging',
                packing: 'Packing Bunk',
                spreading: 'Manure Spreading',
                harvest: 'Harvesting',
                misc: 'Misc'
            },
            validations: {
                required
            }
        },
        cost_type: {
            label: 'Cost Type',
            type: 'select',
            options: [
                'hours',
                'acres',
            ],
            validations: {
                required
            }
        },
        show_manure_fields: {
            label: 'Show Extra Manure Options',
            type: 'check'
        }
    }
}