<template>

    <div class="admin-task-view container-fluid" v-if="!fetching">

        <b-card>
            <router-link class="m-2" to="/admin/dashboard">
                <b-icon-arrow-left></b-icon-arrow-left>
                Back
            </router-link>

            <b-card-header>
                <h2>Task Entries Overview</h2>
            </b-card-header>
            <b-card-body>

                <filter-widget
                    v-model="filters"
                    :assets="assets"></filter-widget>

                <div class="container-xxl">

                    <b-row>
                        <b-col>
                            <b-tabs>
                                <b-tab title="Staff Tasks">
                                    <b-row>
                                        <b-col class="chart-border"
                                               style="min-height: 700px">
                                            <stacked-bar-chart
                                                :chart-data="staffChartData">
                                            </stacked-bar-chart>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                                <!--                            TODO: make responsive on phone-sized displays-->
                                <b-tab title="Acres Per Hour">
                                    <b-row>
                                        <b-col class="col-md-4 col-sm-6"
                                               style="border-bottom: 1px solid #ccc;"
                                               v-for="(value, task_type) in acreChartData" :key="task_type">
                                            <gauge-chart :chart-title="$i18n.gt(taskTypes[task_type])"
                                                         :data-point="value"></gauge-chart>
                                        </b-col>
                                    </b-row>
                                </b-tab>

                                <b-tab title="Export CSV">
                                    <csv-export
                                        :shown-records="shownRecords"
                                        :assets="assets"/>
                                </b-tab>
                            </b-tabs>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <task-table
                                :job-records="shownRecords"
                                :shown-columns="cols"
                                :assets="assets">
                            </task-table>
                        </b-col>
                    </b-row>
                </div>
            </b-card-body>
        </b-card>
    </div>

</template>

<script>
import Vue from 'vue';
import DataService from "../providers/DataService"
import GaugeChart from "../components/GaugeChart"
import LocalAssets from "@/providers/LocalAssets"

export default Vue.extend({
    name: "AdminTaskView",
    components: {GaugeChart},
    data() {
        return {
            jobRecords: null,
            assets: null,

            fetching: true,

            cols: {
                integer_id: 'ID',
                date: 'Date',
                hours: 'Hours',
                first_name: 'First',
                last_name: 'Last',
                task_type: 'Type',
                primary_equipment: 'Primary Equip',
                secondary_equipment: 'Secondary Equip',
                field: 'Field',
                cost: 'Cost',
                edit: 'Edit'
            },

            filters: null,

            taskTypes: LocalAssets.taskTypes,
        }
    },
    computed: {
        shownRecords() {
            return this.filters ? DataService.filterRecords(this.jobRecords, this.filters) : []
        },
        staffChartData() {
            return DataService.chartDataForStaffTaskCount(this.shownRecords, this.assets)
        },
        acreChartData() {
            return DataService.chartDataForAcresPerHour(this.shownRecords, this.assets)
        }
    },
    async mounted() {
        this.jobRecords = await this.$api.getJobRecords()
        this.assets = this.$store.state.assets
        this.fetching = false
        this.$api.log('page: admin task view')
    }
})
</script>

<style scoped>
.chart-border {
    border-bottom: 1px solid #ccc
}
</style>
