<template>
    <div class="stacked-bar-chart">
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>

export default {
    name: "StackedBarChart",
    props: {
        chartData: Object,
    },
    computed: {
        chartOptions() {
            let filledOptions = this.getBaseOptions()
            filledOptions.xAxis.categories = this.chartData.names
            filledOptions.series = this.chartData.series
            return filledOptions
        }
    },
    methods: {
        getBaseOptions() {
            return {
                chart: {
                    type: 'bar',
                    height: 650
                },
                colors: ['#009E82',
                    '#002C45',
                    '#FFDD4A',
                    '#FF7F11',
                    '#79ADDC',
                    '#D1495B',
                    '#404E7C',
                    '#395C6B',
                    '#9E7B9B',
                ],
                title: {
                    text: 'Tasks per Staff Member'
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    categories: null,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Number of Tasks'
                    }
                },
                legend: {
                    reversed: true
                },
                plotOptions: {
                    series: {
                        stacking: 'normal'
                    }
                },
                series: []
            }
        }
    },
}
</script>

<style scoped>

</style>