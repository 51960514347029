import {required} from "vuelidate/lib/validators";

export default {
    fields: {
        first_name: {
            label: 'First Name',
            type: 'text',
            validations: {
                required,
            },
        },
        last_name: {
            label: 'Last Name',
            type: 'text',
            validations: {
                required,
            },
        },
        staff_id: {
            label: 'Staff ID #',
            type: 'text'
        },
        hire_date: {
            label: 'Hire Date',
            type: 'date',
        },
    }
}
