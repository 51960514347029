<template>
    <div class="admin-dashboard container-fluid">
        <h1 class="mt-3">Admin Dashboard</h1>
        <router-link class="m-2" to="/">
            <b-icon-arrow-left></b-icon-arrow-left>
            Back
        </router-link>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-header>
                        <h2>Edit Assets</h2>
                    </b-card-header>
                    <b-card-body>
                        <b-button variant="primary" block to="/admin/edit/staff_member">Edit Staff Members</b-button>
                        <b-button variant="primary" block to="/admin/edit/field">Edit Fields</b-button>
                        <b-button variant="primary" block to="/admin/edit/location">Edit Locations</b-button>
                        <b-button variant="primary" block to="/admin/edit/primary_equipment">Edit Primary Equipment</b-button>
                        <b-button variant="primary" block to="/admin/edit/secondary_equipment">Edit Secondary Equipment</b-button>
                        <b-button variant="primary" block to="/admin/edit/crop">Edit Crops</b-button>
                        <b-button variant="primary" block to="/admin/edit/population">Edit Population</b-button>
                        <b-button variant="primary" block to="/admin/edit/manure_source">Edit Manure Sources</b-button>
                        <b-button variant="primary" block to="/admin/edit/fertilizer">Edit Fertilizers</b-button>
                        <b-button variant="primary" block to="/admin/edit/mix">Edit Spraying Mixes</b-button>
                        <b-button variant="primary" block to="/admin/edit/rate">Edit Spray / Fertilizer Rates</b-button>
                        <b-button variant="primary" block to="/admin/edit/misc_task_type">Edit Misc. Tasks</b-button>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col>
                <b-card>
                    <b-card-header>
                        <h2>View Data</h2>
                    </b-card-header>
                    <b-card-body>
                        <b-button variant="primary" block @click="downloadCsv">Export Data as CSV</b-button>
                        <b-button variant="primary" block to="/admin/view/task">View Job Records</b-button>
                        <b-button variant="primary" block to="/admin/view/field">View Field List</b-button>
                    </b-card-body>
                </b-card>
                <b-card>
                    <b-card-header>
                        <h2>Costs / Seasons</h2>
                    </b-card-header>
                    <b-card-body>
                        <edit-current-season-modal></edit-current-season-modal>
                        <b-button variant="primary" block to="/admin/edit/season">Edit Seasons</b-button>
                        <b-button variant="primary" block to="/admin/costs/edit">Edit Global Costs</b-button>
<!--                            <b-button variant="primary" block to="/admin/costs/view">Cost Overview</b-button>-->
                        <b-button :variant="computing ? 'danger' : 'warning'" block @click="recomputeCosts">
                            {{ computing ? 'Computing ....' : 'Recalculate task costs'}}
                        </b-button>
                    </b-card-body>
                </b-card>
                <b-card>
                    <b-card-header>
                        <h2>Admin</h2>
                    </b-card-header>
                    <b-card-body>
                        <edit-pin-code-modal></edit-pin-code-modal>
                        <b-button variant="primary" block @click="reloadAssets">Reload Assets</b-button>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vue from 'vue'
import DataService from "../providers/DataService";
import CostService from "../providers/CostService";

export default Vue.extend({
    name: "AdminDashboard",
    data() {
        return {
            computing: false,
        }
    },
    methods: {
        async downloadCsv() {
            //let assets = await this.$api.loadAssets()
            let assets = this.$store.state.assets
            let jobRecords = await this.$api.getJobRecords()
            DataService.exportCsv(jobRecords, assets)
        },
        recomputeCosts(){
            this.computing = true

            this.$api.getJobRecords().then((records) => {
                records.forEach((r, i) => {
                    r['cost_breakdown'] = CostService.costBreakdown(r)
                    this.$api.updateJobRecord(r)
                    if(i === records.length - 1) {
                        this.computing = false
                    }
                })
            })
        },
        reloadAssets() {
            this.$api.loadAssets()
            this.$toasted.show('Assets reloaded', {duration: 5000})
        }
    },
    async beforeMount() {
        //This will save them to store and also cache them to local storage
        //await this.$api.loadAssets()    //  loaded in Api.initApp
    },
    mounted() {
        this.$api.log('page: admin dashboard')
    }
})
</script>

<style scoped>

</style>
