<template>
    <div class="data-form-input-typeahead">

        <!-- input for search text/toString of current object -->
        <b-form-input type="text"
                      v-model="searchText"
                      @focus.native="showSelect = true"
                      autocomplete="off"
                      :state="state"
                      debounce="100">
        </b-form-input>

        <!-- list box with search results -->
        <b-form-select v-show="showSelect"
                       :options="results"
                       v-model="selectedResult"
                       @click.native="clickSelect"
                       :select-size="6">
        </b-form-select>
    </div>
</template>

<script>
/**
 * Typeahead component modified from wellcaoches
 *
 * Use v-model to get the selected item
 *
 * @param options Should be an object with id, value, and text
 */
import Vue from 'vue'
import * as _ from 'lodash'

export default Vue.extend({
    name: 'DataFormInputTypeahead',
    props: {
        value: String, //Used for v-model passed into component
        options: Array, //Array of objects formatted for b-form-select
        state: Boolean,
    },
    data() {
        return {
            searchText: '',
            // results: [],
            selectedResult: null,
            showSelect: false,
        }
    },
    computed: {
        results() {
            return this.options.filter((item) => {
                if (item.text) {
                    return item.text.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
                } else {
                    return false
                }
            })
        }
    },
    methods: {
        /**
         * The watcher for selectedResult won't fire if you click the same item,
         * make the box disappear anyways
         */
        clickSelect() {
            this.showSelect = false
        },
        setSelection(val) {
            return this.options.find((item) => {
                return item.id === val
            })
        }
    },
    watch: {
        selectedResult: function (newVal, oldVal) {
            if (newVal) {
                this.$emit('input', newVal) //Setting v-model passed into component

                let selection = this.setSelection(newVal)

                this.searchText = selection.text
                this.showSelect = false
            }
        },
        value: function (newVal, oldVal) {
            this.searchText = this.setSelection(newVal).text
        }
    },
})
</script>

<style lang="scss">
.data-form-input-typeahead {
}
</style>
