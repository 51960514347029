<template>
    <div class="super-admin-dashboard container">
        <b-card v-if="computing">
            <h1>
                Computing ... {{status}}
            </h1>
        </b-card>
        <b-card v-else>
            <b-button @click="recomputeCosts" class="m-3">
                Compute Costs
            </b-button>

            <br>
            <b-button @click="refactorCosts" class="m-3">
                Refactor Costs
            </b-button>
            Assets with "cost: 12" will become "cost_changes: [{timestamp: '2021-01-01', value: 12}]"

            <b-button @click="addCostType" class="m-3">
                Add Cost Type to Equipment
            </b-button>
        </b-card>
    </div>
</template>

<script>
import CostService from "../providers/CostService";

export default {
    name: "SuperAdminDashboard",
    data() {
       return {
           computing: false,
           status: "",
       }
    },
    methods: {
        recomputeCosts(){
            this.computing = true

            this.$api.getJobRecords().then((records) => {
                records.forEach(async (r) => {
                    r['cost_breakdown'] = CostService.costBreakdown(r)
                    await this.$api.updateJobRecord(r)
                })
                this.computing = false
            })
        },
        async refactorCosts() {
            this.computing = true
            let assets = await this.$api.loadAssets()
            _.each(assets, (asset, key) => {
                _.each(asset, async (item, index) => {
                    if(item.cost || item.running_cost) {
                        let cost = item.cost || item.running_cost
                        item['cost_changes'] = [{timestamp: '2021-01-01', value: cost}]
                        await this.$api.setItem(item, key)
                    }
                })
            })
            this.computing = false
        },
        async addCostType() {
            this.computing = true
            let assets = await this.$api.loadAssets()
            _.each(assets['primary_equipment'], async (item, index) => {
                item['cost_type'] = 'hours'
                await this.$api.setItem(item, 'primary_equipment')
            })
            _.each(assets['secondary_equipment'], async (item, index) => {
                item['cost_type'] = 'hours'
                await this.$api.setItem(item, 'secondary_equipment')
            })
            this.computing = false
        }
    }
}
</script>

<style scoped>

</style>
