<template>
    <div class="csv-export mt-2">
        <h2 class="text-center mb-4">CSV Column Selection</h2>
        <b-form-group>
            <b-form-checkbox-group
                v-model="selected"
                :options="options"
                style="column-count: 3"
                stacked/>
            <b-form-checkbox class="btn mb-2 mt-2 ml-2 float-left" v-model="checked" switch>
                <b>Hide Empty Columns</b>
            </b-form-checkbox>
            <b-btn class="btn mb-2 mt-2 ml-2 float-right" @click="resetChecks">
                <b-icon-arrow-counterclockwise font-scale="2"></b-icon-arrow-counterclockwise>
            </b-btn>
            <b-button class="mb-2 mt-2 float-right" variant="primary" @click="downloadCsv">Export Data as CSV</b-button>
        </b-form-group>
    </div>
</template>

<script>

import Vue from 'vue'
import DataService from "@/providers/DataService"
import store from '@/store'
import Utils from "../providers/Utils";

export default Vue.extend({
    name: "CSVExport",
    props: {
        shownRecords: null,
        assets: null
    },
    data() {
        const availableCols = [
            'season',
            'integer_id',
            'staff_member',
            'task_type',
            'date',
            'hours',
            'field',
            'farm',
            'location',
            'primary_equipment',
            'secondary_equipment',
            'maintenance_type',
            'misc_task_type',
            'crop_type',
            'crop',
            'cutting',
            'population',
            'fertilizer',
            'to_location',
            'from_location',
            'spraying_application_type',
            'mix',
            'rate',
            'manure_application_type',
            'gallons',
            'number_pumps',
            'number_trucks',
            'manure_source',
            'cost'
        ]
        return {
            availableCols: availableCols,
            options: availableCols.map(c => ({'text': this.$i18n.gt(c.toUpperCase(), true), 'value': c})),
            selected: availableCols,
            checked: false
        }
    },
    methods: {
        downloadCsv() {
            localStorage.selected = this.selected
            const unselected = this.availableCols.filter(s => !this.selected.includes(s))
            const shownRecords = _.clone(this.shownRecords)
            shownRecords.forEach(r => {
                r.season = Utils.getAsset('season', this.assets).find(s => {
                    return (s.date_end >= r.date) && (s.date_start <= r.date)
                })
            })
            DataService.exportCsv(shownRecords, this.assets, unselected, this.checked)
        },
        resetChecks() {
            this.selected = localStorage.selected = this.availableCols
            this.checked = false
        }
    },
    mounted() {
        if (localStorage.selected) {
            this.selected = localStorage.selected.split(',')
        }
    }
})
</script>

<style scoped>

</style>