import {minValue, maxValue, required} from "vuelidate/lib/validators";

export default {
    fields: {
        text: {
            label: 'Value',
            type: 'number',
            validations: {
                required,
                minValue: minValue(0),
            },
        },
        units: {
            label: 'Unit Type',
            type: 'select',
            options: [
                'population',
                'pounds',
            ],
            validations: {
                required
            }
        },
    }
}
