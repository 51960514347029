<template>
    <div class="user-login container">
        <b-card>
            <b-card-header>
                <h2>Worker Login</h2>
            </b-card-header>
            <b-card-body>
                <b-form @submit.stop.prevent="workerLogin">
                    <b-form-group label="Pin Code">
                        <b-form-input v-model="pin_code"></b-form-input>
                    </b-form-group>
                    <b-button type="submit" variant="primary">Login</b-button>
                </b-form>
            </b-card-body>

            <b-card-header>
                <h2>Admin Login</h2>
            </b-card-header>
            <b-card-body>
                <b-form @submit.stop.prevent="adminLogin">
                    <b-form-group label="Email">
                        <b-form-input v-model="email"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Password">
                        <b-form-input v-model="password"></b-form-input>
                    </b-form-group>
                    <b-button type="submit" variant="primary">Login</b-button>
                </b-form>
            </b-card-body>
        </b-card>
    </div>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: "UserLogin",
    data() {
        return {
            email: '',
            password: '',
            pin_code: '',
        }
    },
    methods: {
        workerLogin() {
            this.$api.signIn(this.pin_code).then(() => {
                    this.$toasted.show('Welcome to Willet!', {duration: 5000})
            },
            (error) => {
                this.$toasted.show(error ? error.message : 'Pin Code Incorrect', {duration: 5000})
            })
        },
        adminLogin() {
            this.$api.signInAdmin(this.email, this.password).then(() => {
                    this.$toasted.show('Welcome Admin!', {duration: 5000})
                },
                (error) => {
                    this.$toasted.show(error.message, {duration: 5000})
                })
        },
    }

})
</script>

<style scoped>

</style>