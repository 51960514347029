import { render, staticRenderFns } from "./EditCurrentSeasonModal.vue?vue&type=template&id=2a770b06&scoped=true"
import script from "./EditCurrentSeasonModal.vue?vue&type=script&lang=js"
export * from "./EditCurrentSeasonModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a770b06",
  null
  
)

export default component.exports