<template>
    <div class="job-record container" v-if="$store.state.translationsLoaded">
        <b-card>
            <router-link class="m-2" :to="returnRoute">
                <b-icon-arrow-left></b-icon-arrow-left>
                Back
            </router-link>

            <b-card-header>
                <h2>{{ recordId ? 'Edit Job Record' : $i18n.gt('ADD_JOB_RECORD') }}</h2>
            </b-card-header>
            <b-card-body>
                <b-form @submit.stop.prevent="submit">
                    <div v-for="(field, fieldName) in taskModel.fields"
                         v-bind:key="fieldName">

                        <b-form-group :label="$i18n.gt( field['label'] )"
                                      :invalid-feedback="invalidFeedback($v, field, fieldName)"
                                      :state="state($v, fieldName)"
                                      v-if="taskModel.fields[fieldName].showIf(form, assets)">

                            <b-form-select v-model.trim="$v.form[fieldName].$model"
                                           v-if="taskModel.fields[fieldName].type === 'select'"
                                           :state="state($v, fieldName)"
                                           :options="taskModel.fields[fieldName].options(form, getAsset(fieldName))">
                            </b-form-select>

                            <b-form-input v-model.trim="$v.form[fieldName].$model"
                                          v-if="taskModel.fields[fieldName].type === 'number'"
                                          step="0.5"
                                          :state="state($v, fieldName)"
                                          type="number">
                            </b-form-input>

                            <time-entry v-model.trim="$v.form[fieldName].$model"
                                        v-if="taskModel.fields[fieldName].type === 'time' && dataLoaded"
                                        :state="state($v, fieldName)">
                            </time-entry>

                            <b-form-input v-model.trim="$v.form[fieldName].$model"
                                          v-if="taskModel.fields[fieldName].type === 'date'"
                                          :state="state($v, fieldName)"
                                          type="date">
                            </b-form-input>

                            <!--                            NB: This only supports one typeahead per form since we are using a ref ...-->
                            <vue-typeahead-bootstrap
                                v-model.trim="$v.form[fieldName].$model"
                                v-if="taskModel.fields[fieldName].type === 'typeahead'"
                                :min-matching-chars="0"
                                ref="typeahead_field"
                                :data="taskModel.fields[fieldName].options(form, getAsset(fieldName))">
                            </vue-typeahead-bootstrap>

                        </b-form-group>
                    </div>

                    <b-button v-if="!!form.task_type"
                              variant="primary"
                              type="submit">
                        {{ $i18n.gt('SUBMIT') }}
                    </b-button>

                    <b-button v-if="!!form.task_type && recordId"
                              variant="danger"
                              class="float-right"
                              @click="deleteRecord">
                        Delete
                    </b-button>
                </b-form>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import Vue from 'vue'
import TaskModel from "../models/Task"
import Validation from "../providers/Validation"
import * as _ from "lodash"
import moment from "moment"
import Utils from "../providers/Utils";
import CostService from "../providers/CostService";
import TimeEntry from "../components/TimeEntry";
import DataFormInputTypeahead from "../components/DataFormInputTypeahead";

export default Vue.extend({
    name: 'JobRecord',
    components: {DataFormInputTypeahead, TimeEntry},
    props: {
        recordId: String, //Present when editing existing record. Null when creating new
    },
    data() {
        const form = Validation.buildFormModel(TaskModel)

        form['date'] = moment().format('Y-MM-DD') //Init form with current date

        return {
            taskModel: TaskModel,
            model: {},
            assets: null,
            form: form,
            dataLoaded: false,

            state: Validation.state,
            invalidFeedback: Validation.invalidFeedback,
        }
    },
    validations() {
        return Validation.buildFormValidations(TaskModel, this.form)
    },
    computed: {
        returnRoute() {
            return this.recordId ? '/admin/view/task' : '/'
        },
    },
    methods: {
        submit() {
            this.saveRecord()
        },
        saveRecord() {
            this.$v.$touch()
            if (!this.$v.$invalid) {

                let submitForm = _.clone(this.form)

                // cleans job-record form (gets dirty switching task types after filling fields)
                const dirty_fields = _.pickBy(this.taskModel.fields, (field, key) => {
                    const fieldHidden = !field.showIf(this.form, this.assets)
                    return fieldHidden && key !== 'id'
                })
                _.forEach(dirty_fields, (value, key) => submitForm[key] = null)

                //Typeahead v-model only gives back string, not id.
                //set the form's field to the ID
                if (this.form['field']) {
                    const foundField = this.getAsset('field').find((f) => {
                        return f.text === this.form['field']
                    })
                    submitForm['field'] = foundField.id
                }

                let promise
                if (this.recordId) {
                    submitForm['cost_breakdown'] = CostService.costBreakdown(submitForm)
                    promise = this.$api.updateJobRecord(submitForm)
                } else {
                    //Add current epoch time to record.
                    //This will be used to sort records and generate numeric IDs
                    submitForm['created'] = Date.now()
                    submitForm['cost_breakdown'] = CostService.costBreakdown(submitForm)

                    promise = this.$api.createJobRecord(submitForm)
                }
                promise.then(() => {
                    this.$toasted.show('Job Record Submitted!', {duration: 5000})
                    this.$router.replace(this.returnRoute)
                }).catch((error) => {
                    this.$toasted.show('Error Submitting Record!', {duration: 5000})
                    this.$router.replace(this.returnRoute)
                })
            }
        },
        getAsset(key) {
            return Utils.getAsset(key, this.assets)
        },
        deleteRecord() {
            this.form['archived'] = true
            this.$api.updateJobRecord(this.form)
            this.$router.replace(this.returnRoute)
        },
    },
    async mounted() {
        this.assets = this.$store.state.assets

        //Load the job record from the recordId passed in via router ...
        //Of course, this is only for editing, not for creating records
        if (this.recordId) {
            let record = await this.$api.getJobRecord(this.recordId)

            //The field is stored by ID in firestore but the silly
            //typeahead component needs the string so we search for it here...
            const foundField = this.getAsset('field').find((f) => {
                return f.id === record['field']
            })
            if (foundField) {
                record['field'] = foundField.text
                //The typeahead component doesn't implement v-model correctly so we need to set its value manually.
                //NB: I fixed this in CACC, TODO: move the CACC typeahead component over here
                this.$refs.typeahead_field[0].inputValue = foundField.text
            }

            Validation.populateFormModel(this.form, TaskModel, record)
        }
        this.dataLoaded = true
        this.$api.log('page: job record')
    }

})
</script>

<style scoped>
</style>
