/**
 * Translation service
 * All translations are stored in a CSV file (for ease of updating), which is loaded at app startup.
 */
import Api from '@/providers/Api'
import * as _ from 'lodash'
import Papa from 'papaparse'
import store from '@/store'

export default {

    translationRows: [],

    loadTranslations() {
        Api.loadTranslations()
            .then((csv) => {
                Papa.parse(csv, {
                    complete: (results, file) => {
                        if (results.errors.length) {
                            console.log('i18n:loadTranslations  results.errors=', results.errors)
                        }
                        this.translationRows = _.concat(this.translationRows, results.data)
                    }
                })
                store.commit('set', {
                    field: 'translationsLoaded',
                    value: true
                })
            })
    },

    switchLanguage() {
        const language = (store.state.language === 'en') ? 'es' : 'en'
        store.commit('set', {
            field: 'language',
            value: language
        })
    },

    /**
     * Returns the translation for a given key in the current language.
     *
     * NB: calling this from a component template will return a bare key
     * if the translations are not loaded yet - so most components should include
     * v-if="$store.state.translationsLoaded" on the main template element!
     *
     * For phrases that require parameter substitution, this can
     * be done using regular string replacement - e.g.:
     *
     *      this.$i18n.gt('MESSAGE_REPORT_MISSING_DATA')
     *         .replace('{enter_them_here}', '...')
     *
     * (but the replacement phrase will sometimes also need translation).
     */
    gt(key, force_en=false) {
        const row = _.find(this.translationRows, (row) => {
            return row[0] === key
        })
        if (row) {
            if (force_en) {
                return row[1]
            }
            return (store.state.language === 'en') ? row[1] : (row[2] ? row[2] : key)
        } else {
            return null
        }
    },
}
