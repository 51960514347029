<template>
    <div class="home container">


        <user-login v-if="!$store.state.user"></user-login>

        <b-card v-else>
            <b-card-header>
                <h2>{{$i18n.gt('HOME')}}</h2>
            </b-card-header>
            <b-card-body>
                <b-button variant="primary" block squared to="/jobrecord">{{$i18n.gt('ADD_JOB_RECORD')}}</b-button>
                <b-button variant="primary" block squared to="/summary">{{$i18n.gt('TODAYS_RECORDS')}}</b-button>

                <b-button v-if="$store.getters.isAdmin" variant="primary" block squared to="/admin/dashboard">Manager Portal</b-button>
                <b-button v-else variant="primary" block squared to="/admin/login">Manager Portal</b-button>

            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'Home',
    data() {
        return {
            selectedUser: null,
            staffList: []
        }
    },
    computed: {
        isAdmin() {
            return this.$store.state.isAdmin
        },
    },
    mounted() {
        this.$api.log('page: home')
    }
})
</script>

<style scoped>
</style>
