<template>
    <div class="edit-pin-code-modal">
        <b-button
          variant="primary" style="margin-bottom: 8px" block
          @click="showModal()">
            Edit Pin Code
        </b-button>
        <b-modal
            :id="'pin_code_modal'"
            @ok="save"
            hide-header>
            <b-form-input v-model="pin_code"></b-form-input>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "EditPinCodeModal",
    data() {
        return {
            pin_code: null,
        }
    },
    methods: {
        showModal() {
            this.$bvModal.show('pin_code_modal')
        },
        save() {
            this.$api.writePinCode(this.pin_code)
        },
    },
    async created() {
        this.pin_code = await this.$api.loadPinCode();
    },
}
</script>

<style scoped>

</style>
