import {required} from "vuelidate/lib/validators";

export default {
    fields: {
        text: {
            label: 'Field Number / Name',
            type: 'text',
            validations: {
                required
            }
        },
        farm: {
            label: 'Associated Farm',
            type: 'select',
            options: [
                "Belltown",
                "Don Robbins",
                "Gary Bradley",
                "Main Farm North",
                "Main Farm South",
                "Main Farm East",
                "West Corey Farm",
            ],
            validations: {
                required
            }
        },
        acreage: {
            label: 'Acreage',
            type: 'number',
            validations: {
                required
            }
        },
        rental_rate: {
            label: 'Rental Rate',
            type: 'number',
        },
        parcel_id: {
            label: 'Parcel ID',
            type: 'text',
        },
    }
}
