import store from '@/store'
import Utils from '@/providers/Utils'
import DataService from "./DataService";

const SEED_UNITS = 80000
const POUND_UNITS = 50

export default {

    /**
     * Calculates total cost from breakdown
     * Returning null indicates a calculation error due to missing data
     * The checks here assume that the task has been properly validated at least,
     * but there may be missing cost information in the breakdown
     */
    calculateCostOfTask(task) {
        let cost = this.computeSubtotals(task)
        return cost?.totalCost
    },

    computeSubtotals(task) {
        let totalCost = 0
        let breakdown = task.cost_breakdown
        let subtotals = {}

        try {
            //All task types have staff cost
            subtotals['staff_member'] = breakdown.staff_member * task.hours
            totalCost += subtotals['staff_member']

            //Equipment
            if (breakdown.primary_equipment) {
                if (task.primary_equipment && task.primary_equipment.cost_type && task.primary_equipment.cost_type === 'acres') {
                    subtotals['primary_equipment'] = breakdown.primary_equipment * task.field.acreage
                    subtotals['primary_equipment_cost_type'] = 'acres'
                } else {
                    subtotals['primary_equipment'] = breakdown.primary_equipment * task.hours
                    subtotals['primary_equipment_cost_type'] = 'hours'
                }
                totalCost += subtotals['primary_equipment']
            }

            if (breakdown.secondary_equipment) {
                if(task.secondary_equipment && task.secondary_equipment.cost_type && task.secondary_equipment.cost_type === 'acres') {
                    subtotals['secondary_equipment'] = breakdown.secondary_equipment * task.field.acreage
                    subtotals['secondary_equipment_cost_type'] = 'acres'
                } else {
                    subtotals['secondary_equipment'] = breakdown.secondary_equipment * task.hours
                    subtotals['secondary_equipment_cost_type'] = 'hours'
                }
                totalCost += subtotals['secondary_equipment']
            }

            if (task.task_type === 'planting') {
                //Seed Cost -- only on planting, not harvesting or other tasks with crop selected
                if(task.crop_type && ['alfalfa', 'canary'].includes(task.crop_type)) {
                    subtotals['crop'] = (task.field.acreage * task.population.text) / POUND_UNITS * breakdown.crop
                    totalCost += subtotals['crop']
                } else {
                    subtotals['crop'] = (task.field.acreage * task.population.text) / SEED_UNITS * breakdown.crop
                    totalCost += subtotals['crop']
                }

                //Fertilizer
                subtotals['fertilizer'] = breakdown.fertilizer * task.rate.text * task.field.acreage
                totalCost += subtotals['fertilizer']
            }

            if (task.task_type === 'spraying') {
                subtotals['spraying'] = breakdown.mix * task.rate.text * task.field.acreage
                totalCost += subtotals['spraying']
            }

            if (task.task_type === 'spreading' && task.number_trucks && task.number_pumps) {
                subtotals['pump_cost'] = breakdown.pump_cost * task.number_pumps * task.hours
                subtotals['truck_cost'] = breakdown.truck_cost * task.number_trucks * task.hours
                subtotals['spreading'] = subtotals['pump_cost'] + subtotals['truck_cost']
                totalCost += subtotals['spreading']
            }

            //If divide by zero or other numeric weirdness occurs
            //A null cost will be displayed as an error to the user
            if (isNaN(totalCost)) {
                return null
            }

            return {
                totalCost: totalCost,
                subtotals: subtotals
            }
        } catch (e) {
            return {
                totalCost: null,
                subtotals: subtotals
            }
        }
    },

    costStatus(task) {
        let variant = 'primary'
        let message = ''
        const breakdown = task.cost_breakdown

        if (!task.hours) {
            variant = 'danger'
            message = 'No Hours'
        }
        if (task.task_type !== 'driving' && task.task_type !== 'maintenance' && task.task_type !== 'packing') {
            if (!task.field) {
                variant = 'danger'
                message = 'No field'
            } else if (!task.field.acreage) {
                variant = 'danger'
                message = 'Field has no acreage'
            }
        }
        if(task.primary_equipment &&
            task.primary_equipment.cost_type &&
            task.primary_equipment.cost_type === 'acres' && (!task.field || !task.field.acreage)) {
            variant = 'danger'
            message = 'Equipment using acreage, but no field set'
        }
        if (!breakdown) {
            variant = 'danger'
            message = 'Calculation Failure'
        }
        if (!breakdown.staff_member) {
            variant = 'danger'
            message = 'No staff'
        }
        if (task.task_type !== 'maintenance' && task.task_type !== 'misc' && !breakdown.primary_equipment) {
            variant = 'danger'
            message = 'No equipment'
        }
        if (task.task_type === 'planting' && (!breakdown.crop || !breakdown.fertilizer || !task.population)) {
            variant = 'danger'
            message = 'No crop, fertilizer, or population'
        }
        if (task.task_type === 'spraying' && (!breakdown.mix || !task.rate)) {
            variant = 'danger'
            message = 'No mix or rate'
        }

        if(task.task_type === 'spreading' && (!breakdown.truck_cost || !breakdown.pump_cost)) {
            variant = 'danger'
            message = 'No manure truck or pump cost'
        }

        return {variant, message}
    },

    /**
     * Get the most recent cost as a number from a cost_changes array
     * e.g. [{timestamp: '2021-05-09', value: 44}, {timestamp: '2021-06-19', value: 30}]
     * would return 30
     */
    mostRecentFromCostChanges(cost_changes) {
        if(cost_changes.length <= 0) {
            return null
        }
        let sorted_costs = _.sortBy(cost_changes, (c) => c.timestamp)
        return +sorted_costs[sorted_costs.length - 1].value
    },

    /**
     * Given an expanded task, and an asset key,
     * returns the most recent cost from that asset's cost_changes
     */
    mostRecentCostForTask(expandedTask, asset_key) {
        if (expandedTask[asset_key] && expandedTask[asset_key].cost_changes) {
            return this.mostRecentFromCostChanges(expandedTask[asset_key].cost_changes)
        }
        return null
    },

    costForDateFromChanges(costChanges, date) {
        if(!costChanges || costChanges.length === 0) {
            return null
        }
        let sortedCosts = _.sortBy(costChanges, (c) => c.timestamp)
        let costChangeForDate = sortedCosts[0]
        sortedCosts.forEach((c) => {
            if (date > c.timestamp) {
                costChangeForDate = c
            }
        })
        return costChangeForDate.value
    },

    costForTaskDate(expandedTask, asset_key) {
        let date = expandedTask.date

        if(date && expandedTask[asset_key] && expandedTask[asset_key].cost_changes) {
            return this.costForDateFromChanges(expandedTask[asset_key].cost_changes, date)
        }

        return null
    },

    costBreakdown(task) {
        //Expanded record replaces IDs with the associated objects
        const expandedTask = DataService.expandJobRecord(task, store.state.assets)
        const globalCosts = store.state.global_costs

        const breakdown = {
            staff_member: this.costForTaskDate(expandedTask, 'staff_member'),
            primary_equipment: this.costForTaskDate(expandedTask, 'primary_equipment'),
            secondary_equipment: this.costForTaskDate(expandedTask, 'secondary_equipment'),
            crop: this.costForTaskDate(expandedTask, 'crop'),
            fertilizer: this.costForTaskDate(expandedTask, 'fertilizer'),
            mix: this.costForTaskDate(expandedTask, 'mix'),

            //The truck and pump cost don't come from a selected asset, so we pull from global costs
            truck_cost: this.costForDateFromChanges(globalCosts.truck_cost),
            pump_cost: this.costForDateFromChanges(globalCosts.pump_cost)
        }

        return breakdown
    },

    /**
     * Assumes tasks contain a cost property
     */
    totalCost(listOfTasks) {
        return _.reduce(listOfTasks, (sum, r) => {
            if (r.cost) {
                return sum + +r.cost
            }
            return sum
        }, 0)
    },

    sumOfHarvestValues(harvests) {
        console.log('CostService:sumOfHarvestValues  harvests=', harvests)
        return _.reduce(harvests, (sum, r) => {
            if (r.gross_value) {
                return sum + +r.gross_value
            }
            return sum
        }, 0)
    },

    harvestRecordsForField(fieldId, seasonId) {
        let harvestRecords = Utils.getAsset('harvest_record', store.state.assets).filter((harvest) => {
            return harvest.field === fieldId
        })
        if(seasonId) {
            return DataService.filterBySeason(harvestRecords, seasonId, 'date')
        }
        return harvestRecords
    },

    /**
     * Gets harvest records for field and appends commodity price, gross value
     */
    expandedHarvestRecordsForField(fieldId, seasonId) {
        let harvestRecords = this.harvestRecordsForField(fieldId, seasonId)
        harvestRecords.forEach((harvest) => {
            harvest.commodity_price = this.costForDateFromChanges(
                store.state.global_costs[harvest.crop_type],
                harvest.date)

            harvest.gross_value = harvest.commodity_price * harvest.yield
        })
        return harvestRecords
    },

    totalExpensesForField(jobRecords, fieldId, seasonId) {
        jobRecords = DataService.filterBySeason(jobRecords, seasonId, 'date')
        jobRecords = _.filter(jobRecords, (record) => {
            return record.field === fieldId
        })
        jobRecords = DataService.expandJobRecords(jobRecords, store.state.assets, true)
        return this.totalCost(jobRecords)
    }



}
