import {required} from "vuelidate/lib/validators";

export default {
    fields: {
        text: {
            label: 'Fertilizer',
            type: 'text',
            validations: {
                required
            }
        },
    }
}
