<template>
    <div class="cost-breakdown" @click="showModal">
        <cost-badge
            v-if="task.cost_breakdown"
            :status="costStatus"
            :cost="cost">
        </cost-badge>
        <b-badge v-else
                 class="cost"
                 variant="danger">
           $ Missing
        </b-badge>

        <b-modal :id="modalId" hide-header size="lg">
            <h5>Cost Breakdown</h5>
            <div class="table-responsive" v-if="task.cost_breakdown">
                <table class="table">
                    <thead>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>Cost</td>
                        <td>Unit $</td>
                        <td>Hours</td>
                        <td>Acreage</td>
                        <td>Notes</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Labor</td>
                        <td>{{getText('staff_member')}}</td>
                        <td><cost-badge :cost="getSubtotal('staff_member')"></cost-badge></td>
                        <td><cost-badge :cost="Number(task.cost_breakdown.staff_member)"></cost-badge></td>
                        <td>{{task.hours}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr v-if="!['misc', 'maintenance'].includes(task.task_type)">
                        <td>Primary Equipment</td>
                        <td>{{getText('primary_equipment')}}</td>
                        <td><cost-badge :cost="getSubtotal('primary_equipment')"></cost-badge></td>
                        <td><cost-badge :cost="Number(task.cost_breakdown.primary_equipment)"></cost-badge></td>
                        <td>
                            <template v-if="getSubtotal('primary_equipment_cost_type') === 'hours'">
                                {{task.hours}}
                            </template>
                        </td>
                        <td>
                            <template v-if="getSubtotal('primary_equipment_cost_type') === 'acres'">
                                {{acreage}}
                            </template>
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="task.secondary_equipment">
                        <td>Secondary Equipment</td>
                        <td>{{getText('secondary_equipment')}}</td>
                        <td><cost-badge :cost="getSubtotal('secondary_equipment')"></cost-badge></td>
                        <td><cost-badge :cost="Number(task.cost_breakdown.secondary_equipment)"></cost-badge></td>
                        <td>
                            <template v-if="getSubtotal('secondary_equipment_cost_type') === 'hours'">
                                {{task.hours}}
                            </template>
                        </td>
                        <td>
                            <template v-if="getSubtotal('secondary_equipment_cost_type') === 'acres'">
                                {{acreage}}
                            </template>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr v-if="task.task_type === 'planting'">
                        <td>Seed Cost</td>
                        <td>{{getText('crop')}}</td>
                        <td><cost-badge :cost="getSubtotal('crop')"></cost-badge></td>
                        <td><cost-badge :cost="Number(task.cost_breakdown.crop)"></cost-badge></td>
                        <td></td>
                        <td>{{acreage}}</td>
                        <td>Population: {{getText('population')}}</td>
                    </tr>
                    <tr v-if="task.task_type === 'planting'">
                        <td>Fertilizer</td>
                        <td>{{getText('fertilizer')}}</td>
                        <td><cost-badge :cost="getSubtotal('fertilizer')"></cost-badge></td>
                        <td><cost-badge :cost="Number(task.cost_breakdown.fertilizer)"></cost-badge></td>
                        <td></td>
                        <td>{{acreage}}</td>
                        <td>Rate: {{getText('rate')}} gal/acre</td>
                    </tr>
                    <tr v-if="task.task_type === 'spraying'">
                        <td>Spraying</td>
                        <td>{{getText('mix')}}</td>
                        <td><cost-badge :cost="getSubtotal('spraying')"></cost-badge></td>
                        <td><cost-badge :cost="Number(task.cost_breakdown.spraying)"></cost-badge></td>
                        <td></td>
                        <td>{{acreage}}</td>
                        <td>Rate: {{getText('rate')}} gal/acre</td>
                    </tr>
                    <tr v-if="task.task_type === 'spreading'">
                        <td>Manure - Trucks</td>
                        <td></td>
                        <td><cost-badge :cost="getSubtotal('truck_cost')"></cost-badge></td>
                        <td><cost-badge :cost="Number(task.cost_breakdown.truck_cost)"></cost-badge></td>
                        <td>{{task.hours}}</td>
                        <td></td>
                        <td>Trucks: {{task.number_trucks}}</td>
                    </tr>
                    <tr v-if="task.task_type === 'spreading'">
                        <td>Manure - Pumps</td>
                        <td></td>
                        <td><cost-badge :cost="getSubtotal('pump_cost')"></cost-badge></td>
                        <td><cost-badge :cost="Number(task.cost_breakdown.pump_cost)"></cost-badge></td>
                        <td>{{task.hours}}</td>
                        <td></td>
                        <td>Pumps: {{task.number_pumps}} Gallons: {{task.gallons}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="table-foot">
                        <td>Total</td>
                        <td></td>
                        <td>${{ cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </b-modal>
    </div>
</template>

<script>
import CostService from "@/providers/CostService"
import {TaskCostBreakdown} from "@/providers/CostService"
import CostBadge from "./CostBadge";

export default {
    name: "CostBreakdown",
    components: {CostBadge},
    props: {
        task: Object
    },
    computed: {
        cost() {
            return CostService.calculateCostOfTask(this.task)
        },
        costStatus() {
            return CostService.costStatus(this.task)
        },
        acreage() {
            if(this.task && this.task.field && this.task.field.acreage) {
                return this.task.field.acreage
            } else {
                return '?'
            }
        },
        subtotals() {
            let cost = CostService.computeSubtotals(this.task)
            return cost?.subtotals
        },
        modalId() {
            return 'modal_' + Math.floor(Math.random() * 1000000)
        },
    },
    methods: {
        showModal() {
            this.$bvModal.show(this.modalId)
        },
        getText(prop) {
           if(this.task && this.task[prop]) {
               if(this.task[prop].text) {
                   return this.task[prop].text
               }
           } else {
               return '?'
           }
        },
        getSubtotal(key) {
            return this.subtotals && this.subtotals[key]
        }
    },
}
</script>
<style scoped>
.table-foot {
    color: white;
    background-color: #002C45;
    font-size: 18px;
}

.cost {
    font-weight: bold;
    font-size: 18px;
}
</style>