import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import i18n from './i18n'

export default {

    // generate a random firestore-style ID
    generateId() {
        const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        let id = ''
        for (let i = 0; i < 20; i++) {
            id += CHARS.charAt(
                Math.floor(Math.random() * CHARS.length)
            )
        }
        return id
    },

    /**
     * Gets the asset item given an ID.
     * Will return undefined if ID is not found in assets.
     */
    getAssetItem(id, assetKey, assets) {
        if (assets){
            return assets[assetKey][id]
        }
        return null
    },

    /**
     * Gets asset from the assets array and remaps items to
     * be formatted for bootstrap vue
     *
     * Removes archived items from the list
     */
    getAsset(key, assets, filterArchived=true) {

        //Driving pulls from the location asset but the names of the
        //fields in the Task model are to_location and from_location
        if (key === 'to_location' || key === 'from_location') {
            key = 'location'
        }

        if (assets[key]) {
            //Map the id to value for BootstrapVue select and filter away archived
            let items = Object.values(assets[key]).map((item) => {
                item['value'] = item.id

                //If its a staff member, it has first_name / last_name instead of text
                if(key === 'staff_member') {
                    item['text'] = item?.first_name + ' ' + item?.last_name
                }

                return item
            })

            if (filterArchived)
            items = items.filter((item) => {
                return !item.archived
            })

            // sorts alphabetically ignoring case
            return items.sort((a, b) => {
                return a.text?.toLowerCase().localeCompare(b.text?.toLowerCase());
            })
        }
    },

    /**
     * Remove null / undefined keys from an object.
     *
     * This is used before saving an existing job record.
     * Firestore is ok with adding new keys to an object before saving (e.g. archived)
     * IF AND ONLY IF it is not null. The vuelidate form stuff adds a bunch of empty keys
     * which we strip away with this function.
     */
    scrub(obj) {
        Object.keys(obj).forEach((key) => {
            if(obj[key] == null){
               delete obj[key]
            }
        })
        return obj
    },

    translateOptions(options) {
        return _.mapValues(options, (key) => {
            return i18n.gt(key)
        })
    },

    filterArchived(asset) {
        return Object.values(asset).filter((item) => {
            return !item.archived
        })
    },

    // globally register all components in the 'src/components' directory (using kebab-case)
    registerComponents() {
        const requireComponent = require.context(
            // The relative path of the components folder
            '@/components',
            // Whether or not to look in subfolders
            true,
            // The regular expression used to match base component filenames
            /[A-Z]\w+\.(vue|js)$/
        )

        requireComponent.keys().forEach(fileName => {
            // Get component config
            const componentConfig = requireComponent(fileName)

            // Get PascalCase name of component
            const componentName = upperFirst(
                camelCase(
                    fileName
                        .split('/')
                        .pop()
                        .replace(/\.\w+$/, ''),
                )
            )

            const kebabName = componentName.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()

            // Register component globally
            Vue.component(
                kebabName,
                // Look for the component options on `.default`, which will
                // exist if the component was exported with `export default`,
                // otherwise fall back to module's root.
                componentConfig.default || componentConfig
            )
        })
    },

    // add a custom Vue mixin to allow a given service to be accessed from any
    // child component, e.g. as 'this.$i18n'
    addCustomMixin(name) {
        Vue.mixin({
            beforeCreate() {
                const options = this.$options
                if (options[name]) {
                    this['$' + name] = options[name]
                } else if (options.parent && options.parent['$' + name]) {
                    this['$' + name] = options.parent['$' + name]
                }
            }
        })
    },
}
