<template>
    <div class="user-menu">

        <b-navbar toggleable="lg" type="dark" class="navbar">

            <b-navbar-brand to="/">
                <img class="logo" src="/img/obh.png">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav class="mt-2">
                <b-navbar-nav class="ml-auto">
                    <b-nav-item class="nav-item">
                        <b-badge v-if="$store.getters.isAdmin" class="m-2" variant="info">
                            Admin User
                        </b-badge>
                    </b-nav-item>

                    <b-nav-item>
                        <b-badge v-if="$store.state.devMode" class="m-2" :variant="farmIdVariant">
                            {{ $api.farm_id }}
                        </b-badge>
                    </b-nav-item>

                    <b-nav-item>
                        <a @click="switchLanguage()"><h5 class="text-white">{{langSwitchLabel}}</h5></a>
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>

        </b-navbar>
        <div class="notify note-red" v-if="$store.state.unsynced">
            New records have not yet been saved
        </div>
        <div class="notify note-green" v-if="$store.state.unsynced === false">Records saved</div>


    </div>

</template>

<script>
export default {
    name: "UserMenu",
    methods: {
        switchLanguage() {
            this.$i18n.switchLanguage()
        }
    },
    computed: {
        langSwitchLabel(){
           return this.$store.state.language === 'es' ? 'ENGLISH' : 'ESPAÑOL'
        },
        farmIdVariant(){
            return this.$api.farm_id === 'willet_production' ? "danger" : "warning"
        }
    }
}
</script>

<style scoped>

.navbar {
    background-color: #002C45;
}

.note-red {
    background-color: #FF6666;
}

.note-green {
    background-color: #009E82;
}

.nav-item {
    /*border-style: solid;*/
    /*border-width: 2px;*/
    /*border-color: white;*/
}

.notify {
    color: white;
    width: 100%;
    height: 30px;
    text-align: center;
}

.logo {
    display: flex;
    height: 64px;

}

</style>
