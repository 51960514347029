export default {
    fields: {
        text: {
            label: 'Crop / Seed Variety',
            type: 'text',
        },
        type: {
            label: 'Crop Type',
            type: 'select',
            options: [
                'alfalfa',
                'canary',
                'corn',
                'grain_corn',
                'grain',
                'other'
            ]
        },
        cost: {
            label: 'Cost',
            type: 'number',
        }
    }
}
