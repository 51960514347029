import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import api from "@/providers/Api";
import * as path from "path";
import firebase from "firebase";

Vue.use(VueRouter)

const PUBLIC = 0
const USER = 1
const ADMIN = 2

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: require('@/pages/Home').default,
            meta: {
                securityLevel: PUBLIC
            }
        },
        {
            path: '/jobrecord',
            component: require('@/pages/JobRecord').default,
            meta: {
                securityLevel: USER
            }
        },
        {
            path: '/assigned',
            component: require('@/pages/AssignedJobs').default,
            meta: {
                securityLevel: USER
            }
        },
        {
            path: '/summary',
            component: require('@/pages/TaskSummary').default,
            meta: {
                securityLevel: USER
            }
        },
        {
            path: '/public/summary',
            component: require('@/pages/TaskSummary').default,
            meta: {
                securityLevel: PUBLIC
            }
        },
        {
            path: '/admin/login',
            component: require('@/pages/AdminLogin').default,
            meta: {
                securityLevel: PUBLIC
            }
        },
        {
            path: '/admin/dashboard',
            component: require('@/pages/AdminDashboard').default,
            meta: {
                securityLevel: ADMIN
            }
        },
        {
            path: '/admin/edit/:assetKey',
            component: require('@/pages/AdminAssetEditor').default,
            props: (route) => ({assetKey: route.params.assetKey}),
            meta: {
                securityLevel: ADMIN
            }
        },
        {
            path: '/admin/view/task',
            component: require('@/pages/AdminTaskView').default,
            meta: {
                securityLevel: ADMIN
            }
        },
        {
            path: '/admin/view/field',
            component: require('@/pages/AdminFieldList').default,
            meta: {
                securityLevel: ADMIN
            }
        },
        {
            path: '/admin/view/field/:fieldId',
            component: require('@/pages/AdminFieldView').default,
            props: (route) => ({fieldId: route.params.fieldId}),
            meta: {
                securityLevel: ADMIN
            }
        },
        {
            path: '/admin/jobrecord/:recordId',
            component: require('@/pages/JobRecord').default,
            props: (route) => ({recordId: route.params.recordId}),
            meta: {
                securityLevel: ADMIN
            }
        },
        {
            path: '/admin/costs/edit',
            component: require('@/pages/GlobalCostEditor').default,
            meta: {
                securityLevel: ADMIN
            }
        },
        {
            path: '/admin/costs/view',
            component: require('@/pages/CostOverview').default,
            meta: {
                securityLevel: ADMIN
            }
        },
        {
            path: '/superadmin',
            component: require('@/pages/SuperAdminDashboard').default,
            meta: {
                securityLevel: ADMIN
            }
        }
    ]
})

router.beforeEach(async(to, from, next) => {
    if(!store.state.user) {
        firebase.auth().onAuthStateChanged((user) => {
            store.commit('set', {
                field: 'user',
                value: user
            })
            next()
        })
    } else {
        next()
    }
})

router.afterEach(async(to, from, next) => {
    let allow = false

    if (to.meta.securityLevel === PUBLIC) {
        allow = true
    } else if (to.meta.securityLevel === USER) {
        allow = !!store.state.user
    } else if (to.meta.securityLevel === ADMIN) {
        allow = store.getters.isAdmin
    }

     if (!allow) {
         router.app.$toasted.error('You do not have authorization to view this page', {duration: 5000})
         await router.push('/')
     }
})

export default router
