<template>
    <div class="profit-widget">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <td>Date</td>
                    <td>Yield (Tons)</td>
                    <td>Commodity Price</td>
                    <td>Gross Value</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(harvest, i) in harvestRecords" :key="i">
                    <td>{{ harvest.date }}</td>
                    <td>{{ harvest.yield }}</td>
                    <td>
                        <cost-badge
                            :cost="Number(harvest.commodity_price)"
                            :append="harvest.crop_type"></cost-badge>
                    </td>
                    <td>
                        <cost-badge :cost="Number(harvest.gross_value)"></cost-badge>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"></td>
                    <td><strong>Total Gross Value:</strong></td>
                    <td>
                        <cost-badge :cost="totalGrossValue"></cost-badge>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"></td>
                    <td><strong>Total Expenses:</strong></td>
                    <td>
                        <cost-badge class="d-inline-block" :cost="totalExpenses"></cost-badge>
                    </td>
                </tr>
                <tr class="total-row">
                    <td colspan="2"></td>
                    <td><strong>Field Profit:</strong></td>
                    <td>
                        <b-badge :variant="profitVariant">{{ profitString }}</b-badge>
                    </td>
                </tr>
                </tbody>
            </table>
            <b-alert show variant="info"><i>Note: The date filter does not apply to the profit calculation. All harvests and tasks from the current season are used.</i></b-alert>
        </div>
    </div>
</template>

<script>
import CostService from "../providers/CostService";

export default {
    name: "ProfitWidget",
    props: {
        fieldId: String,
        seasonId: String,
        jobRecords: Array,
    },
    computed: {
        harvestRecords() {
            return CostService.expandedHarvestRecordsForField(this.fieldId, this.seasonId)
        },
        totalGrossValue() {
            return CostService.sumOfHarvestValues(this.harvestRecords)
        },
        totalExpenses() {
            console.log('ProfitWidget:totalExpenses  this.jobRecords=', this.jobRecords)
            return CostService.totalExpensesForField(this.jobRecords, this.fieldId, this.seasonId)
        },
        profit() {
            return (this.totalGrossValue - this.totalExpenses)
        },
        profitString() {
            if (this.profit < 0) {
                return '-$' + (0 - this.profit).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
            }
            return '$' + this.profit.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        profitVariant() {
            if (this.profit < 0) {
                return 'danger'
            }
            return 'success'
        }
    },
}
</script>

<style scoped>

.total-row {
    color: white;
    background-color: #002C45;
}

.total-row {

}

</style>
