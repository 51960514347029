<template>
    <div class="cost-overview">
        <item-table
            :shown-columns="shownColumns"
            :items="items">
        </item-table>

    </div>
</template>

<script>
export default {
    name: "CostOverview",
    data() {
        return {
            shownColumns: {
                name: 'Asset Name',
                cost: 'Cost',
            },
            items: [{name: 'Thing', cost: 44}]
        }
    },
    async mounted() {
        this.$api.log('page: cost overview')
    }
}
</script>

<style scoped>

</style>
