/**
 * Local Assets
 *
 * Hard-coded 'assets' should be kept here.
 * Unlike the assets stored in firestore, these can't be changed by admins
 *
 */
export default {

    taskTypes: {
        driving: 'DRIVING',
        maintenance: 'MAINTENANCE',
        tillage: 'TILLAGE',
        mowing: 'MOWING',
        merging: 'MERGING',
        packing: 'PACKING_BUNK',
        harvesting: 'HARVESTING',
        spreading: 'MANURE_SPREADING',
        planting: 'PLANTING',
        spraying: 'SPRAYING_CROPS',
        misc: 'MISC',
    },

    cropTypes: {
        alfalfa: 'ALFALFA',
        canary: 'CANARY',
        corn: 'CORN',
        grain_corn: 'GRAIN_CORN',
        grain: 'GRAIN',
        other: 'OTHER'
    },

    maintenanceTypes: {
        equipment: 'EQUIPMENT',
        building_facility: 'BUILDING_FACILITY',
        grounds_property: 'GROUNDS_PROPERTY'
    },

    sprayingApplicationTypes: {
        pre_emergent: 'PRE_EMERGENT', //TODO: Remove once Henry finishes converting assets
        post_emergent: 'POST_EMERGENT',//TODO: Remove once Henry finishes converting assets

        pre_emergent_corn: 'PRE_EMERGENT_CORN',
        post_emergent_corn: 'POST_EMERGENT_CORN',
        post_emergent_alfalfa: 'POST_EMERGENT_ALFALFA',
        top_dress: 'TOP_DRESS',
        fungicide: 'FUNGICIDE',
        burn_down: 'BURN_DOWN',
    },

    manureApplicationTypes: {
        drag_line: 'DRAG_LINE',
        frack_tank: 'FRACK_TANK',
        tank_spread: 'TANK_SPREAD',
        compost_solids: 'COMPOST_SOLIDS'
    }

}