<template>
    <div class="cost-change-editor">
        <b-button
            class="btn btn-block btn-sm"
            @click="showModal()">
            <b-icon-cash-stack font-scale="2"></b-icon-cash-stack>
        </b-button>
        <b-modal
            @ok="save"
            hide-header
            :id="id">
            <b-card-body>
                <b-row>
                    <item-table
                        empty-message="No cost changes yet"
                        :shown-columns="shownColumns"
                        :items="costs">
                        <template v-slot:customField="slotProps">
                            <div v-if="slotProps.row.col === 'value'">
                                <b-form-input
                                    v-model="slotProps.row.item.value"
                                    :disabled="itemLocked(slotProps.row.item)">
                                </b-form-input>
                            </div>
                            <div v-if="slotProps.row.col === 'timestamp'">
                                <b-form-input
                                    v-model="slotProps.row.item.timestamp"
                                    :disabled="itemLocked(slotProps.row.item)"
                                    type="date">
                                </b-form-input>
                            </div>
                            <div v-if="slotProps.row.col === 'delete'">
                                <b-button
                                    @click="deleteItem(slotProps.row.index)"
                                    :disabled="itemLocked(slotProps.row.item)"
                                    class="btn-small" variant="dark">
                                    <b-icon-x font-scale="1.5"></b-icon-x>
                                </b-button>
                            </div>
                        </template>
                    </item-table>
                </b-row>
                <b-row>
                    <b-col cols="3">
                        <b-form-group label="Edit Old">
                            <b-button @click="toggleLock" block :variant="locked ? 'danger' : 'secondary'">
                                <b-icon-lock v-if="locked" font-scale="2"></b-icon-lock>
                                <b-icon-unlock v-else font-scale="2"></b-icon-unlock>
                            </b-button>
                        </b-form-group>
                    </b-col>
                    <b-col cols="9">
                        <b-form-group label="New Cost Change">
                            <b-button @click="addCost" block>
                                <b-icon-plus-circle font-scale="2"></b-icon-plus-circle>
                            </b-button>
                        </b-form-group>
                    </b-col>
                </b-row>
                <template v-if="showErrors">
                    <b-alert v-model="dateError" variant="danger">Dates must be in ascending order.</b-alert>
                    <b-alert v-model="nullError" variant="danger">Missing a date or cost value</b-alert>
                </template>
            </b-card-body>
        </b-modal>
    </div>
</template>

<script>
/**
 * Edits cost_changes, which is an array of objects with
 * a timestamp and a cost value for that date:
 *
 * cost_changes: [
 *      {timestamp: '2021-05-05', value: 16},
 *      {timestamp: '2021-08-08', value: 32},
 * ]
 *
 * When the modal is opened the passed-in records 'costChanges' are saved to data attribute 'costs',
 * so that we won't save any changes if they cancel and then reopen the modal.
 *
 * Existing records should be 'locked' unless
 * the button is clicked to unlock them.
 * Validation ensures that there are no null values and that dates are in ascending order.
 * Saving should be handled by the parent when save-costs is emitted
 */
export default {
    name: "CostChangeEditor",
    props: {
        id: String,
        costChanges: Array,
        item: Object, //The whole asset 'item', used for saving
    },
    data() {
        return {
            shownColumns: {
                timestamp: 'Time',
                value: 'Cost',
                delete: 'Delete'
            },
            locked: true, //Used to disable existing records
            costs: [], //Same as prop costChanges, but set when modal pops up
            showErrors: false,
        }
    },
    computed: {
        dateError() {
            let lessThan = false
            this.costs.forEach((cost, index) => {
                if (index > 0) {
                    lessThan ||= Date.parse(this.costs[index].timestamp) <
                        Date.parse(this.costs[index - 1].timestamp)
                }
            })
            return lessThan
        },
        nullError() {
            return this.costs.reduce((prev, cost) => {
                return !cost.timestamp || !cost.value || prev
            }, false)
        },
    },
    methods: {
        addCost() {
            //Add unlocked to allow new entries to be edited
            //We will remove this key before submitting
            this.costs.push({timestamp: null, value: null, unlocked: true})
        },
        toggleLock() {
            this.locked = !this.locked
        },
        itemLocked(item) {
            return this.locked && !item.unlocked
        },
        showModal() {
            if (this.costChanges) {
                this.costs = _.cloneDeep(this.costChanges)
            } else {
                this.costs = []
            }
            this.locked = true
            this.showErrors = false
            this.$bvModal.show(this.id)
        },
        deleteItem(index) {
            this.costs.splice(index, 1)
        },
        save(evt) {
            if (this.dateError || this.nullError) {
                this.showErrors = true
                evt.preventDefault()
            } else {
                //remove 'unlocked'
                let emittedCosts = this.costs.map((cost) => {
                    return {timestamp: cost.timestamp, value: cost.value}
                })
                console.log('CostChangeEditor:save  emittedCosts=', emittedCosts)
                this.$emit('save-costs', this.item, emittedCosts)
            }
        }
    },
}
</script>

<style scoped>

</style>
