import store from '@/store'
import Utils from '@/providers/Utils'
import LocalAssets from "../providers/LocalAssets"
import {minValue, maxValue, required} from "vuelidate/lib/validators"
import i18n from "@/providers/i18n"

/**
 * Maps primary equipment to task types.
 * Pieces of primary equipment with that type (truck, tractor ...)
 * will only show up as options on the Job Record form when they have selected one
 * of the task types in its list here
 *
 * Contractor is a special type with a cost of 0, used when a contractor brings their
 * own equipment
 */
const primary_equip_to_tasks = {
    truck: ['driving'],
    tractor: ['tillage', 'planting', 'mowing', 'merging', 'spreading', 'spraying', 'packing', 'misc'],
    chopper: ['harvesting'],
    other: ['misc'],
    contractor: ['spraying']
}

/**
 * There is a check box 'show extra manure fields' when adding
 * secondary equipment in the admin portal. This function looks through the
 * assets to see if that box is checked for the piece of secondary equipment that
 * has been selected in the Job Record model.
 *
 * This is needed since below each options(model, asset) function
 * can only look at its own asset. Will need to reconsider this ...
 */
function showExtraManureFields(model) {

    //model.secondary_equipment is actually an ID
    //Util.getAssetItem will fetch the whole item with that ID
    //In the case we are using the copy we put in the store
    let secondaryEquipment = Utils.getAssetItem(model.secondary_equipment,
        'secondary_equipment',
        store.state.assets)

    return ['spreading'].includes(model.task_type) &&
        secondaryEquipment?.show_manure_fields
}

/**
 * Put the append string after each item's text
 * Used for adding unit labels e.g. '10' becomes '10 lbs / acre'
 */
function appendAssetText(asset, append) {
    return asset.map((item) => {
        let string = item.text + append
        return {
            text: string,
            value: item.value
        }
    })
}

const inFieldList = (value) => {
    const foundField = Utils.getAsset('field', store.state.assets).find((f) => {
        return f.text === value
    })
    return !!foundField
}

/**
 * This is where the magic happens / sausage is made !
 * Every field on the 'new job record' page is setup here in this form model.
 *
 * The 'type' can be select, number, date, or typeahead.
 * If it is a select, the options function will return the valid choices.
 *
 * The options function takes 'model' : an instance of this form model.
 * And 'asset': is the asset with the same name/key as that field.
 * I.e. when 'options' is called for primary_equipment, it will only be passed the primary_equipment asset.
 *
 * You may need to use other assets from the store, as showExtraManureFields does.
 * TODO: Refactor away this double way of fetching assets
 */
export default {
    fields: {

        //Hidden fields so that populating vuelidate keeps them in model
        id: {
            showIf: (model) => {
                return false
            }
        },
        /**
         * Common Fields - Staff, Task Type
         */
        staff_member: {
            label: 'STAFF_MEMBER',
            type: 'select',
            validations: {
                required,
            },
            invalidFeedback: {required: 'STAFF_MEMBER_REQUIRED'},
            options: (model, asset) => {
                return asset.map((staff) => {
                    staff['text'] = staff.first_name + ' ' + staff.last_name
                    return staff
                })
            },
            showIf: (model) => {
                return true
            }
        },
        task_type: {
            label: 'TASK',
            type: 'select',
            options: (model, asset) => {
                return Utils.translateOptions(LocalAssets.taskTypes)
            },
            showIf: (model) => {
                return true
            }
        },
        date: {
            label: 'DATE',
            type: 'date',
            validations: {
                required,
            },
            invalidFeedback: {required: 'DATE_REQUIRED'},
            showIf: (model) => {
                return true
            }
        },
        hours: {
            label: 'HOURS',
            type: 'time',
            validations: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(24)
            },
            invalidFeedback: {
                required: 'HOURS_REQUIRED',
                minValue: 'HOURS_TOO_LOW',
                maxValue: 'HOURS_TOO_HIGH'
            },
            showIf: (model) => {
                return true
            }
        },
        field: {
            label: 'FIELD',
            type: 'typeahead',
            validations: {
                required,
                inFieldList, //Custom validator, checks in field asset
            },
            invalidFeedback: {
                required: 'FIELD_REQUIRED',
                inFieldList: 'INVALID_FIELD'
            },
            options: (model, asset) => {
                //The typeahead component can only take the text, not the whole object
                //This is kind of a PITA since we have to then
                //convert the text back into an ID when we save ... see JobRecord.vue
                return asset.map((field) => {
                    return field.text
                })
            },
            showIf: (model) => {
                return !['maintenance', 'packing', 'driving'].includes(model.task_type)
            }
        },
        location: {
            label: 'LOCATION',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return asset
            },
            showIf: (model) => {
                return ['maintenance', 'packing'].includes(model.task_type)
            }
        },
        /**
         * Common Fields - Equipment, Crop Type, Crop
         */
        primary_equipment: {
            label: 'PRIMARY_EQUIPMENT',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return asset.filter((equip) => {
                    if (equip.type) {
                        return primary_equip_to_tasks[equip.type].includes(model.task_type)
                    } else {
                        return false
                    }
                })
            },
            showIf: (model) => {
                return !['maintenance'].includes(model.task_type)
            },
        },
        secondary_equipment: {
            label: 'SECONDARY_EQUIPMENT',
            type: 'select',
            options: (model, asset) => {
                return asset.filter((equip) => {
                    if (equip.type) {
                        return equip.type === model.task_type
                    } else {
                        return false
                    }
                })
            },
            showIf: (model) => {
                return !['maintenance', 'spraying', 'driving'].includes(model.task_type)
            },
        },
        /**
         * Simple Tasks - Maintenance, Tillage, Misc.
         */
        maintenance_type: {
            label: 'MAINTENANCE_TYPE',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return Utils.translateOptions(LocalAssets.maintenanceTypes)
            },
            showIf: (model) => {
                return model.task_type === 'maintenance'
            }
        },
        misc_task_type: {
            label: 'MISC_TASK_TYPE',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return asset
            },
            showIf: (model) => {
                return model.task_type === 'misc'
            }

        },
        crop_type: {
            label: 'CROP_TYPE',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return Utils.translateOptions(LocalAssets.cropTypes)
            },
            showIf: (model) => {
                return ['mowing', 'merging', 'packing', 'harvesting', 'planting'].includes(model.task_type)
            }
        },
        crop: {
            label: 'CROP_VARIETY',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return asset.filter((crop) => {
                    return crop.type === model.crop_type
                })
            },
            showIf: (model) => {
                return ['planting'].includes(model.task_type)
            }
        },
        cutting: {
            label: 'CUTTING_NUM',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return [1, 2, 3, 4, 5]
            },
            showIf: (model) => {
                return ['harvesting', 'merging', 'mowing'].includes(model.task_type) &&
                    ['alfalfa', 'canary'].includes(model.crop_type)
            }
        },
        population: {
            label: 'POPULATION',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                if(['canary', 'alfalfa', 'grain', 'other'].includes(model.crop_type)) {
                    return asset.filter((item) => {
                        return item.units === 'pounds'
                    })
                } else if (['corn', 'grain_corn',].includes(model.crop_type)) {
                    return asset.filter((item) => {
                        return item.units === 'population'
                    })
                }
            },
            showIf: (model) => {
                return ['planting'].includes(model.task_type)
            }
        },
        fertilizer: {
            label: 'FERTILIZER',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return asset
            },
            showIf: (model) => {
                return ['planting'].includes(model.task_type)
            }
        },
        /**
         * Driving
         *
         * NB: There are no to_location, or from_location assets!
         * These are derived from the location asset. See JobRecord.vue getAsset()
         */
        to_location: {
            label: 'TO',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return asset
            },
            showIf: (model) => {
                return ['driving'].includes(model.task_type)
            }
        },
        from_location: {
            label: 'FROM',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return asset
            },
            showIf: (model) => {
                return ['driving'].includes(model.task_type)
            }
        },
        /**
         * Fertilizer Spraying
         */
        spraying_application_type: {
            label: 'APPLICATION_TYPE',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return Utils.translateOptions(LocalAssets.sprayingApplicationTypes)
            },
            showIf: (model) => {
                return ['spraying'].includes(model.task_type)
            }
        },
        mix: {
            label: 'MIX',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return asset.filter((mix) => {
                    return mix.type === model.spraying_application_type
                })
            },
            showIf: (model) => {
                return ['spraying'].includes(model.task_type)
            }
        },
        //Used for both spraying rate and fertilizer rate
        rate: {
            label: 'RATE',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return appendAssetText(asset, ' gal / acre')
            },
            showIf: (model) => {
                return ['spraying', 'planting'].includes(model.task_type)
            }
        },
        /**
         * Manure Spreading
         */
        manure_application_type: {
            label: 'APPLICATION_TYPE',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return Utils.translateOptions(LocalAssets.manureApplicationTypes)
            },
            showIf: (model) => {
                return ['spreading'].includes(model.task_type)
            }
        },
        gallons: {
            label: 'GALLONS',
            type: 'number',
            validations: {
                required,
            },
            showIf: (model) => {
                return ['spreading'].includes(model.task_type)
            }
        },
        number_pumps: {
            label: 'NUMBER_PUMPS',
            type: 'number',
            showIf: (model) => {
                return showExtraManureFields(model)
            }
        },
        number_trucks: {
            label: 'NUMBER_TRUCKS',
            type: 'number',
            showIf: (model) => {
                return showExtraManureFields(model)
            }
        },
        manure_source: {
            label: 'MANURE_SOURCE',
            type: 'select',
            options: (model, asset) => {
                return asset
            },
            showIf: (model) => {
                return showExtraManureFields(model)
            }
        }


    }
}