<template>

    <div class="item-edit-modal">
        <b-button
            v-if="!noButton"
            class="btn btn-block btn-sm" variant="primary"
            @click="showModal()">
            <b-icon-pencil font-scale="2"></b-icon-pencil>
        </b-button>

        <b-modal
            @ok="save"
            @cancel="cancel"
            hide-header
            :id="id">
            <b-form-group v-for="(field, fieldName) in fieldModel.fields"
                          :invalid-feedback="invalidFeedback($v, field, fieldName)"
                          :key="fieldName"
                          :label="field.label">

                <b-input-group :append="field.units">
                    <b-form-select class='input-box' v-model.trim="$v.form[fieldName].$model"
                                   v-if="field.type === 'select'"
                                   :state="state($v, fieldName)"
                                   :options="getOptions(field.options)">
                    </b-form-select>
                    <b-form-checkbox v-model.trim="$v.form[fieldName].$model"
                                     :state="state($v, fieldName)"
                                     v-else-if="field.type === 'check'">
                    </b-form-checkbox>
                    <cost-change-editor v-model.trim="$v.form[fieldName].$model"
                                        :state="state($v, fieldName)"
                                        v-else-if="field.type === 'cost'">
                    </cost-change-editor>
                    <b-form-input v-model.trim="$v.form[fieldName].$model"
                                  :state="state($v, fieldName)"
                                  v-else
                                  :type="field.type">
                    </b-form-input>
                </b-input-group>
            </b-form-group>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue'
import * as _ from 'lodash'
import Validation from "../providers/Validation";
import {required} from "vuelidate/lib/validators";
import TaskModel from "../models/Task";
import CostChangeEditor from "./CostChangeEditor";

/**
 * Modal for editing assets and items.
 * Has an open button unless noButton is true
 * FieldModel may contain a validation section and an optional invalidFeedback
 *
 * Calling the API should be handled by the parent
 * Emits an event, save-item which passes item and assetKey. Asset key can be null if not needed
 */
export default Vue.extend({
    name: "ItemEditModal",
    components: {CostChangeEditor},
    props: {
        id: String, //Must be unique for page, used to show modal
        fieldModel: Object,
        assetKey: String, //Second parameter called when emitting save-item
        item: Object,
        noButton: Boolean,
    },
    data() {
        const form = Validation.buildFormModel(this.fieldModel)

        return {
            savedItem: null,
            form: form,

            state: Validation.state,
            invalidFeedback: Validation.invalidFeedback,
        }
    },
    validations() {
        return Validation.buildFormValidations(this.fieldModel)
    },
    methods: {
        showModal() {
            Validation.populateFormModel(this.form, this.fieldModel, this.item)
            this.$bvModal.show(this.id)
            /*
             * Make a copy of the original item in case they click cancel
             * todo: might want to make this similar to cost change editor,
             * i.e. we save the item prop to a data element and manipulate the data
             * instead of manipulating the prop, then emit that data element on save-item
             */
            this.savedItem = _.clone(this.item)
        },
        async save(evt) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                evt.preventDefault()
            } else {
                Validation.extractFormModel(this.$v.form, this.fieldModel, this.item)
                this.$emit('save-item', this.item, this.assetKey)
            }
        },
        cancel() {
            //Restore the original item
            this.item = _.clone(this.savedItem)
            Validation.populateFormModel(this.form, this.fieldModel, this.item)
        },
        getOptions(options) {
            if (_.isFunction(options)) {
                return options(this.fieldModel)
            }
            return options
        }
    },
    beforeMount() {
    }
})
</script>

<style scoped>
</style>