import Papa from 'papaparse'
import * as _ from 'lodash'
import Utils from "./Utils"
import LocalAssets from "./LocalAssets";
import store from '@/store'
import i18n from "@/providers/i18n";
import CostService from "./CostService";

/**
 * DataService contains functions for aggregating and formatting data,
 * used for CSV exporting and Highcharts.
 *
 * Most functions expect JobRecords and Assets as parameters
 */
export default {

    /**
     * Applies filters from the filter widget to job records
     */
    filterRecords(records, filters) {
        if (filters.task_type) {
            records = records.filter((record) => {
                return filters.task_type.includes(record.task_type)
            })
        }

        if (filters.staff_member) {
            records = records.filter((record) => {
                return filters.staff_member === record.staff_member
            })
        }

        if (filters.integer_id) {
            records = records.filter((record) => {
                return filters.integer_id === record.integer_id.toString()
            })
        }

        if (filters.season) {
            records = records.filter((record) => {
                let season = Utils.getAssetItem(filters.season, 'season', store.state.assets)
                return (season.date_end >= record.date) &&
                    (season.date_start <= record.date)
            })
        }

        if (filters.date_start) {
            records = records.filter((record) => {
                return filters.date_start <= record.date
            })
        }

        if (filters.date_end) {
            records = records.filter((record) => {
                return filters.date_end >= record.date
            })
        }

        if (filters.field) {
            records = records.filter((record) => {
                return filters.field === record.field
            })
        }

        return records
    },

    getFieldName(id) {
        const field = Utils.getAssetItem(id, 'field', store.state.assets)
        return field?.text
    },

    filterBySeason(items, seasonId, dateKey='date') {
        let season = Utils.getAssetItem(seasonId, 'season', store.state.assets)
        return items.filter((item) => {
            return (season.date_end >= item[dateKey]) &&
                (season.date_start <= item[dateKey])
        })
    },

    /**
     * For each task type, gives a count of that task for each staff member.
     * Staff Member's are stored by ID hash.
     * If they have completed 0 of a task, they won't be present in its list
     *
     * Returned structure:
     * {
     *     driving: [
     *       2qdhJ4LVbSraXqPON8e1: 2
     *       h5UFA8W0yE3TzCuAV7VI: 1
     *      ],
     *      harvesting: [ ... ]
     *      ...
     * }
     */
    taskCountByStaff(jobRecords) {
        return _(jobRecords).groupBy('task_type').mapValues((tasks) => {
            return _(tasks).groupBy('staff_member').mapValues((tasks) => {
                return _.size(tasks)
            }).valueOf()
        }).valueOf()
    },

    /**
     * Returns both a series and matching xAxis labels for
     * charting the staff task count in a stacked bar chart.
     */
    chartDataForStaffTaskCount(jobRecords, assets) {
        let taskCounts = this.taskCountByStaff(jobRecords)

        let staff = Utils.filterArchived(assets.staff_member)  //This is all of the staff. Asset names are never plural
        let taskTypes = LocalAssets.taskTypes

        let series = Object.keys(taskTypes).map((task) => {

            return {
                name: i18n.gt(taskTypes[task]),
                data: Object.values(staff).map((staff_member) => {
                    //If task or staff not present in the task counts, default to 0
                    return taskCounts[task]?.[staff_member.id] ?? 0
                }),
            }
        })

        let names = staff.map((s) => {
            return s.first_name + ' ' + s.last_name
        })

        return {
            series: series,
            names: names
        }
    },

    /**
     * For each task type,
     * Calculate the average acres per hour.
     * Fetches the acreage of the field used in the task from assets,
     * and divides by the hours logged for that task.
     * If either of these is missing the task will be skipped.
     */
    chartDataForAcresPerHour(jobRecords, assets) {

        let records = _.filter(jobRecords, (r) => {
            return ['mowing', 'merging', 'spreading', 'planting', 'tillage', 'harvesting'].includes(r.task_type)
        })

        records = _.map(records, (r) => {
            const field = Utils.getAssetItem(r.field, 'field', assets)
            return {
                acreage: field?.acreage,
                hours: r.hours,
                task_type: r.task_type
            }
        })

        records = _.filter(records, r => !!r.acreage)

        records = _.groupBy(records, 'task_type')
        records = _.mapValues(records, (r) => {
            return _.mean(_.map(r, (n) => {
                return (n.acreage / n.hours)
            }))
        })
        return records
    },

    expandJobRecord(record, assets, include_cost = false) {
        let output = {}

        output.season = record?.season

        output.id = record?.id
        output.integer_id = record?.integer_id
        output.hours = record?.hours
        output.date = record?.date

        let staffMember = Utils.getAssetItem(record.staff_member, 'staff_member', assets)
        output.first_name = staffMember?.first_name
        output.last_name = staffMember?.last_name
        output.staff_member = staffMember

        output.task_type = record?.task_type

        let field = Utils.getAssetItem(record.field, 'field', assets)
        output.field = field

        output.farm = field?.farm

        let location = Utils.getAssetItem(record.location, 'location', assets)
        output.location = location

        let primaryEquipment = Utils.getAssetItem(record.primary_equipment, 'primary_equipment', assets)
        output.primary_equipment = primaryEquipment

        let secondaryEquipment = Utils.getAssetItem(record.secondary_equipment, 'secondary_equipment', assets)
        output.secondary_equipment = secondaryEquipment

        output.crop_type = record?.crop_type

        let crop = Utils.getAssetItem(record.crop, 'crop', assets)
        output.crop = crop

        output.cutting = record?.cutting

        let population = Utils.getAssetItem(record.population, 'population', assets)
        output.population = population

        let fertilizer = Utils.getAssetItem(record.fertilizer, 'fertilizer', assets)
        output.fertilizer = fertilizer

        let mix = Utils.getAssetItem(record.mix, 'mix', assets)
        output.mix = mix

        let fromLocation = Utils.getAssetItem(record.from_location, 'location', assets)
        output.from_location = fromLocation

        let toLocation = Utils.getAssetItem(record.to_location, 'location', assets)
        output.to_location = toLocation

        let rate = Utils.getAssetItem(record.rate, 'rate', assets)
        output.rate = rate

        output.manure_application_type = record?.manure_application_type

        let manure_source = Utils.getAssetItem(record.manure_source, 'manure_source', assets)
        output.manure_source = manure_source

        output.number_pumps = record?.number_pumps
        output.number_trucks = record?.number_trucks
        output.gallons = record?.gallons

        output.spraying_application_type = record?.spraying_application_type

        let misc_task_type = Utils.getAssetItem(record.misc_task_type, 'misc_task_type', assets)
        output.misc_task_type = misc_task_type

        output.maintenance_type = record?.maintenance_type

        output.cost_breakdown = record?.cost_breakdown

        //Calculate cost with the expanded fields
        if (include_cost) {
            output.cost = CostService.calculateCostOfTask(output)
        }

        return output
    },

    expandJobRecords(jobRecords, assets, include_cost = false) {
        return jobRecords.map((record) => {
            return this.expandJobRecord(record, assets, include_cost)
        })
    },

    expandJobRecordsJustText(jobRecords, assets, include_cost = false) {
        const records = this.expandJobRecords(jobRecords, assets, include_cost)
        return records.map((record) => {
            record.season = record.season?.text
            record.field = record.field?.text
            record.location = record.location?.text
            record.from_location = record.from_location?.text
            record.to_location = record.to_location?.text
            record.primary_equipment = record.primary_equipment?.text
            record.rate = record.rate?.text
            record.secondary_equipment = record.secondary_equipment?.text
            record.crop = record.crop?.text
            record.population = record.population?.text
            record.fertilizer = record.fertilizer?.text
            record.mix = record.mix?.text
            record.manure_source = record.manure_source?.text
            record.misc_task_type = record.misc_task_type?.text
            return record
        })
    },

    removeEmptyCols(array) {
        const keys = Object.keys(array[0])
        const notEmpty = []

        array.forEach(a => {
            keys.forEach(k => {
                if (a[k] != null) {
                    notEmpty.push(k)
                }
            })
        })

        const empty = keys.filter(k => !notEmpty.includes(k))
        return array.map(a => {
            empty.forEach(e => delete a[e])
            return a
        })
    },

    /**
     * Modfiy record for csv export
     */
    removeColumns(jobRecords, unselected = null, hideEmpty = false) {
        let records = jobRecords.map((r) => {
            delete r.id
            delete r.staff_member
            delete r.cost_breakdown

            if (unselected) {
                unselected.forEach(u => {
                    if (u === 'staff_member') {
                        delete r.first_name
                        delete r.last_name
                    } else {
                        delete r[u]
                    }
                })
            }
            return r
        })

        if (hideEmpty) {
            records = this.removeEmptyCols(records)
        }

        return records
    },

    renameColumns(records) {
        return records.map(r => {
            Object.keys(r).forEach(old_key => {
                let new_key = i18n.gt(old_key.toUpperCase(), true)
                if (old_key !== new_key) {
                    Object.defineProperty(r, new_key, Object.getOwnPropertyDescriptor(r, old_key))
                    delete r[old_key]
                }
            })
            return r
        })
    },

    /**
     * Generate a csv from all the job records and download it.
     * If any fields in the job record are asset ID hashes, they will be expanded into the asset's properties
     */
    exportCsv(jobRecords, assets, unselected = [], hideEmpty = false) {
        const include_cost = !unselected.includes('cost')
        jobRecords = this.expandJobRecordsJustText(jobRecords, assets, include_cost)
        jobRecords = this.removeColumns(jobRecords, unselected, hideEmpty)
        jobRecords = this.renameColumns(jobRecords)
        let csv = Papa.unparse(jobRecords)
        let csvData = new Blob([csv], {type: 'text/plain'});
        const a = document.createElement('a');
        a.href = URL.createObjectURL(csvData);
        a.download = 'WilletDairyExport.csv'

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
}