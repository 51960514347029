<template>
    <div id="app">

        <header class="header">
            <user-menu></user-menu>
        </header>

        <div class="content content-outer">
            <spinner-until :condition="pageReady">
                <router-view/>
            </spinner-until>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import SpinnerUntil from "@/components/SpinnerUntil";

export default Vue.extend({
    name: 'app',
    components: {SpinnerUntil},
    computed: {
        pageReady() {
            return this.$store.state.assets &&
                this.$store.state.global_costs &&
                this.$store.state.translationsLoaded
        }
    },
    mounted() {
        this.$api.initApp()
        this.$i18n.loadTranslations()
    },
})

</script>

<style lang="scss">

/* base styles for app */
@import '../sass/base';

#app {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    > .content {
        flex: 1;
    }
}

</style>

