import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import toasted from 'vue-toasted'
import Utils from './providers/Utils'
import 'firebase/firestore'
import {firestorePlugin} from 'vuefire'
import './registerServiceWorker'
import VueMq from 'vue-mq'
import Vuelidate from "vuelidate"
import VueTypeaheadBootstrap from 'vue-bootstrap-typeahead'

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.config.performance = true

Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)
Vue.use(firestorePlugin)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(toasted)

import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)
import Highcharts from 'highcharts'
import * as more from 'highcharts/highcharts-more'
import * as gauge from 'highcharts/modules/solid-gauge'

more(Highcharts)
gauge(Highcharts)


Vue.use(VueMq, {
    breakpoints: {
        sm: 540,
        md: 720,
        lg: 960,
        xl: 1140,
    },
    defaultBreakpoint: 'sm'
})

Utils.registerComponents()

import api from '@/providers/Api'
import i18n from '@/providers/i18n'

Utils.addCustomMixin('api')
Utils.addCustomMixin('i18n')


new Vue({
    router,
    store,
    api,
    i18n,
    render: h => h(App)
}).$mount('#app')
