import {minValue, maxValue, required} from "vuelidate/lib/validators";

const validCost = (value) => {
   return true
}

export default {
    fields: {
        text: {
            label: 'Text',
            type: 'text',
            validations: {
               required
            }
        },
        type: {
            label: 'Type',
            type: 'select',
            options: [
                'truck',
                'tractor',
                'chopper',
                'other',
                'contractor'
            ],
            validations: {
                required
            }
        },
        cost_type: {
            label: 'Cost Type',
            type: 'select',
            options: [
                'hours',
                'acres',
            ],
            validations: {
                required
            }
        }
    }
}