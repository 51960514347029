<template>
    <div class="task-summary container-fluid" v-if="!fetching">
        <b-card>
            <b-card-header>
                <h2>Tasks Entered Today</h2>
            </b-card-header>
            <b-card-body>
                <div class="container-xxl">
                    <b-row>
                        <b-col style="height: 700px;">
                            <stacked-bar-chart
                                :chart-data="chartData">
                            </stacked-bar-chart>
                        </b-col>
                    </b-row>
                </div>
            </b-card-body>
        </b-card>
    </div>

</template>

<script>
import Utils from "../providers/Utils";
import DataService from "../providers/DataService";
import LocalAssets from "../providers/LocalAssets";
import moment from "moment"
import store from "@/store";

export default {
    name: "TaskSummary",
    data() {
        return {
            selectedField: null,
            assets: null,
            fetching: true,
        }
    },
    computed: {
        shownRecords() {
            let today = moment().format('Y-MM-DD')
            let filters = {
                date_start: today,
                date_end: today,
            }
            return DataService.filterRecords(this.jobRecords, filters)
        },
        chartData() {
            return DataService.chartDataForStaffTaskCount(this.shownRecords, this.assets)
        }
    },
    methods: {
        getAsset(key) {
            return Utils.getAsset(key, this.assets)
        }
    },
    async mounted() {
        this.jobRecords = await this.$api.getJobRecords()
        //console.log('TaskSummary:jobRecords.length=', this.jobRecords.length)
        //console.log('TaskSummary:store.state.jobRecords=', store.state.jobRecords)
        this.assets = this.$store.state.assets
        this.fetching = false
        this.$api.log('page: task summary')
    }
}
</script>

<style scoped>

</style>
