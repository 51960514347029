import store from "@/store";
import {maxValue, minValue, required} from "vuelidate/lib/validators";
import Utils from "@/providers/Utils";
import LocalAssets from "@/providers/LocalAssets";

const inFieldList = (value) => {
    const foundField = store.state.assets.field.find((f) => {
        return f.text === value
    })
    return !!foundField
}

const inSeasonList = (value) => {
    const foundSeason = store.state.assets.season.find((s) => {
        return s.text === value
    })
    return !!foundSeason
}

export default {
    fields: {
        date: {
            label: 'Date',
            type: 'date',
            validations: {
                required,
            },
            invalidFeedback: {required: 'You must enter a date.'}
        },
        crop_type: {
            label: 'Crop Type',
            type: 'select',
            validations: {
                required,
            },
            options: (model, asset) => {
                return Utils.translateOptions(LocalAssets.cropTypes)
            }
        },
        yield: {
            label: 'Yield',
            text: 'number',
            validations: {
                required,
                minValue: minValue(0),
            }
        }
    }
}

