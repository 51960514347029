<template>
    <div class="global-cost-editor container-fluid">

        <b-card>
            <router-link class="m-2" to="/admin/dashboard">
                <b-icon-arrow-left></b-icon-arrow-left>
                Back
            </router-link>

            <b-card-header>
                <h2>Cost Editor</h2>
            </b-card-header>
            <b-card-body v-if="$store.state.global_costs">
                <h5>Manure Spreading Costs</h5>
                <item-table
                    :shown-columns="shownColumns"
                    :items="manure_costs">
                    <template v-slot:customField="slotProps">
                        <div v-if="slotProps.row.col === 'latest_cost'">
                            <cost-badge :cost="latestCosts[slotProps.row.item.key]"></cost-badge>
                        </div>
                        <div v-else-if="slotProps.row.col === 'editor'">
                            <cost-change-editor
                                :item="slotProps.row.item"
                                :cost-changes="costs[slotProps.row.item.key]"
                                @save-costs="saveCosts"
                                :id="'manure_cost' + slotProps.row.index">
                            </cost-change-editor>
                        </div>
                        <div v-else>
                            {{slotProps.row.item.cost_type}}
                        </div>
                    </template>
                </item-table>

                <h5>Commodity Prices</h5>
                <item-table
                    :shown-columns="shownColumns"
                    :items="commodities">
                    <template v-slot:customField="slotProps">
                        <div v-if="slotProps.row.col === 'latest_cost'">
                            <cost-badge :cost="latestCosts[slotProps.row.item.key]"></cost-badge>
                        </div>
                        <div v-else-if="slotProps.row.col === 'editor'">
                            <cost-change-editor
                                :item="slotProps.row.item"
                                :cost-changes="costs[slotProps.row.item.key]"
                                @save-costs="saveCosts"
                                :id="'commodity_cost' + slotProps.row.index">
                            </cost-change-editor>
                        </div>
                        <div v-else>
                            {{slotProps.row.item.cost_type}}
                        </div>
                    </template>
                </item-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import CostChangeEditor from "../components/CostChangeEditor";

export default {
    name: "GlobalCostEditor",
    components: {CostChangeEditor},
    data() {
        return {
            shownColumns: {
                cost_type: 'Cost Type',
                latest_cost: 'Latest Cost',
                editor: 'Editor',
            },
            commodities: [
                {cost_type: 'Alfalfa', key: 'alfalfa'},
                {cost_type: 'Corn Silage', key: 'corn'},
                {cost_type: 'Grain Corn', key: 'grain_corn'},
                {cost_type: 'Canary', key: 'canary'},
                {cost_type: 'Grain', key: 'grain'},
            ],
            manure_costs: [
                {cost_type: 'Manure Truck Cost', key: 'truck_cost'},
                {cost_type: 'Manure Pump Cost', key: 'pump_cost'},
            ]
        }
    },
    computed: {
        latestCosts() {
           let cost_changes = this.$store.state.global_costs
            cost_changes = _.mapValues(cost_changes, (costs, key) => {
                if(costs) {
                    return Number(costs[costs.length - 1]?.value)
                }
                return null
            })
            return cost_changes
        },
        costs() {
            return this.$store.state.global_costs
        },
    },
    methods: {
        async saveCosts(item, cost_changes) {
            let global_costs = this.$store.state.global_costs
            global_costs[item.key] = cost_changes
            await this.$api.setGlobalCost(global_costs)
            this.$forceUpdate()
            console.log('GlobalCostEditor:saveCosts  this.$store.state.global_costs=', this.$store.state.global_costs)
        },
        latestCost(key) {
            const costs = this.getCostsFor(key)
            if (costs) {
                return costs[costs.length - 1]?.value
            }
            return null
        }
    },
    mounted() {
        this.$api.log('page: global cost editor')
    }
}
</script>

<style scoped>

</style>
