import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@/providers/Api'
import * as _ from "lodash";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null, //Firebase user object returned by sign in functions
        isAdmin: false,
        devMode: false,
        unsynced: null,

        jobRecords: null,
        assets: null,
        global_costs: null,

        filters: null,

        language: 'en',
        translationsLoaded: false
    },
    getters: {
        isAdmin: (state) => {
            return state.user ? !state.user.isAnonymous : false
        },
    },
    mutations: {
        initStore(state) {
            if (localStorage.getItem('vuex')) {
                const savedState = JSON.parse(localStorage.getItem('vuex'))
                _.each(savedState, (value, key) => {
                    state[key] = value
                })
            }
        },
        set(state, { field, value }) {
            state[field] = value
        },
        clearAssoc(state, field) {
            state[field] = {}
        },
        setAssoc(state, { field, key, value }) {
            if (key) {
                if (!state[field]) {
                    state[field] = {}
                }
                state[field][key] = value
            }
        }
    },
    actions: {
    },
    modules: {}
})
