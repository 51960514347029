import { render, staticRenderFns } from "./JobRecord.vue?vue&type=template&id=ca8e0dd8&scoped=true"
import script from "./JobRecord.vue?vue&type=script&lang=js"
export * from "./JobRecord.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca8e0dd8",
  null
  
)

export default component.exports