<template>
    <div class="admin-login container">
        <b-card>
            <b-card-header>
                <h2>Admin Login</h2>
            </b-card-header>
            <b-card-body>
                <b-form @submit.stop.prevent="login">
                    <b-form-group label="Email">
                        <b-form-input v-model="email"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Password">
                        <b-form-input v-model="password" type="password"></b-form-input>
                    </b-form-group>
                    <b-button type="submit" variant="primary">Login</b-button>
                </b-form>
            </b-card-body>
        </b-card>
    </div>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: "AdminLogin",
    data() {
        return {
            email: '',
            password: '',
        }
    },
    methods: {
        login() {
            this.$api.signInAdmin(this.email, this.password).then(() => {
                    this.$router.replace('/')
                    this.$toasted.show('Welcome Admin!', {duration: 5000})
                },
                (error) => {
                    this.$toasted.show(error.message, {duration: 5000})
                })
        },
    },
    mounted() {
        this.$api.log('page: admin login')
    }
})
</script>

<style scoped>

</style>
