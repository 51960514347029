<template>
    <div class="admin-field-view container-fluid" v-if="!fetching">
        <b-card>
            <router-link class="m-2" to="/admin/dashboard">
                <b-icon-arrow-left></b-icon-arrow-left>
                Back
            </router-link>

            <b-card-header>
                <h2>Field Overview for {{ field.text }}</h2>
            </b-card-header>
            <b-card-body>

                <filter-widget v-model="filters" :assets="assets" :frozen-field="fieldId"></filter-widget>

                <div class="container-xxl">
                    <b-row>
                        <b-col>
                            <b-tabs>
                                <b-tab title="Task Entries" class="">
                                    <task-table
                                        :job-records="shownRecords"
                                        :shown-columns="cols"
                                        :assets="assets">
                                    </task-table>
                                </b-tab>
                                <b-tab title="Manure Records">
                                    <task-table
                                        :job-records="manureShownRecords"
                                        :shown-columns="manure_cols"
                                        :assets="assets">
                                    </task-table>
                                </b-tab>
                                <b-tab title="Staff Tasks">
                                    <b-row>
                                        <b-col class="chart-border"
                                               style="min-height: 700px">
                                            <stacked-bar-chart
                                                :chart-data="staffChartData">
                                            </stacked-bar-chart>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                                <!--                            TODO: make responsive on phone-sized displays-->
                                <b-tab title="Acres Per Hour">
                                    <b-row>
                                        <b-col class="col-md-4 col-sm-6"
                                               style="border-bottom: 1px solid #ccc;"
                                               v-for="(value, task_type) in acreChartData" :key="task_type">
                                            <gauge-chart :chart-title="$i18n.gt(taskTypes[task_type])"
                                                         :data-point="value"></gauge-chart>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                                <b-tab title="Harvest Records">
                                    <asset-table
                                        :show-header="false"
                                        :emit-save="true"
                                        @save-asset="saveAsset"
                                        :asset-key="'harvest_record'"
                                        :asset-model="harvestRecord"
                                        :table-columns="harvestRecordColumns"
                                        :filters="filters">
                                    </asset-table>
                                </b-tab>
                                <b-tab title="Profit">
                                    <profit-widget
                                        :job-records="jobRecords"
                                        :field-id="fieldId"
                                        :season-id="filters && filters.season">
                                    </profit-widget>
                                </b-tab>
                            </b-tabs>
                        </b-col>
                    </b-row>
                </div>
            </b-card-body>
        </b-card>
    </div>

</template>

<script>
import Utils from "../providers/Utils";
import DataService from "../providers/DataService";
import AssetTable from "@/components/AssetTable";
import Field from "@/models/Field";
import HarvestRecord from "@/models/HarvestRecord";
import Vue from "vue";
import LocalAssets from "@/providers/LocalAssets";

export default Vue.extend({
    name: "AdminFieldView",
    components: {AssetTable},
    props: {
        fieldId: String,
    },
    data() {
        return {
            field: null,
            assets: null,
            fetching: true,
            harvestRecord: HarvestRecord,
            harvestRecordColumns: ['date', 'crop_type', 'yield'],
            jobRecords: [],

            cols: {
                integer_id: 'ID',
                date: 'Date',
                hours: 'Hours',
                first_name: 'First',
                last_name: 'Last',
                task_type: 'Type',
                primary_equipment: 'Primary Equip',
                secondary_equipment: 'Secondary Equip',
                field: 'Field',
                cost: 'Cost',
                edit: 'Edit'
            },

            manure_cols: {
                integer_id: 'ID',
                date: 'Date',
                hours: 'Hours',
                first_name: 'First',
                last_name: 'Last',
                task_type: 'Type',
                primary_equipment: 'Primary Equip',
                secondary_equipment: 'Secondary Equip',
                manure_source: 'Manure Source',
                gallons: 'Gallons',
                number_pumps: 'No. Pumps',
                number_trucks: 'No. Trucks',
                field: 'Field',
                cost: 'Cost',
                edit: 'Edit'
            },

            filters: null,

            taskTypes: LocalAssets.taskTypes,
        }
    },
    computed: {
        shownRecords() {
            return this.filters ? DataService.filterRecords(this.jobRecords, this.filters) : []
        },
        manureShownRecords() {
            if(this.filters) {
                let filters = _.clone(this.filters)
                filters.task_type = ['spreading']
                return DataService.filterRecords(this.jobRecords, filters)
            }
            return []
        },
        staffChartData() {
            return DataService.chartDataForStaffTaskCount(this.shownRecords, this.assets)
        },
        acreChartData() {
            return DataService.chartDataForAcresPerHour(this.shownRecords, this.assets)
        }
    },
    methods: {
        async saveAsset(item, assetKey) {
            item.field = this.fieldId
            await this.$api.setItem(item, assetKey)
            this.assets = await this.$api.loadAssets()
        }
    },
    async mounted() {
        this.jobRecords = await this.$api.getJobRecords()
        //this.assets = await this.$api.loadAssets()
        this.assets = this.$store.state.assets
        this.fetching = false

        this.field = Utils.getAssetItem(this.fieldId, 'field', this.assets)
        this.$api.log('page: admin field view')
    }
})
</script>

<style scoped>
.chart-border {
    border-bottom: 1px solid #ccc
}
</style>
