import { render, staticRenderFns } from "./GlobalCostEditor.vue?vue&type=template&id=3816dc43&scoped=true"
import script from "./GlobalCostEditor.vue?vue&type=script&lang=js"
export * from "./GlobalCostEditor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3816dc43",
  null
  
)

export default component.exports